import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import ButtonCircle from '../common/ButtonCircle';
import PageContainer from '../common/PageContainer';
import TextBanner from '../typography/TextBanner';
import getSlug from '../../utils/getSlug';

const StyledBackgroundColor = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	height: 100%;
	position: relative;
	width: 100%;
`;

const StyledBackgroundWrapper = styled.div`
	align-items: center;
	background-image: url(${({ bgImg }) => bgImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	height: 115vh;
	justify-content: center;
	width: 100vw;
	@media (min-width: 400px) {
		height: 100vh;
	}
	@media (min-width: 1240px) {
		background-attachment: fixed;
	}
`;

const StyledModuleWrapper = styled.div`
	padding: 50px 0;
	width: 100%;
	@media ${({ theme }) => theme.device.w_1440} {
		flex-direction: row;
		align-items: ${({ buttonPosition }) => buttonPosition};
		display: flex;
		justify-content: space-between;
	}
`;

const StyledTextWrapper = styled.div`
	padding: 40px 0;
	width: 100%;
	@media ${({ theme }) => theme.device.w_425} {
		padding: 110px 0px 40%;
	}
	@media (min-width: 768px) {
		//padding-right: 25%;
	}
	@media ${({ theme }) => theme.device.w_1024} {
		width: 100%;
		padding: 90px 0;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		width: 100%;
		padding: 220px 0;
	}
	@media ${({ theme }) => theme.device.w_1920} {
		width: 75%;
	}
	@media ${({ theme }) => theme.device.w_2560} {
		width: 55%;
	}
`;

const StyledButtonWrapper = styled.div`
	position: relative;
	text-align: right;
	@media ${({ theme }) => theme.device.w_1440} {
		text-align: center;
		margin-bottom: 24px;
	}
`;

const PhotoDescriptionButton = ({ data, activeSite }) => {
	const moduleData = data?.modulZdjecieZOpisem?.[0];

	const description = moduleData?.opis || '';
	const buttonHref = moduleData?.przyciskHref || '';
	const buttonDescription = moduleData?.przyciskTekst || '';
	const buttonLink = moduleData?.przyciskLink?.[0]?.slug || '';
	const buttonPosition = moduleData?.przyciskPolozenie || '';
	const img = moduleData?.zdjecie[0].url || '';

	return (
		<PageContainer className="full-width">
			<StyledBackgroundColor>
				<StyledBackgroundWrapper bgImg={img}>
					<StyledModuleWrapper buttonPosition={buttonPosition} className="inner-wrapper-small padding-right">
						<Fade bottom>
							<StyledTextWrapper>
								<TextBanner className="photoDescription grey-light thin">{description}</TextBanner>
							</StyledTextWrapper>
							{(buttonLink.length > 0 || buttonHref.length > 0) && buttonDescription && (
								<StyledButtonWrapper>
									<ButtonCircle to={`/${getSlug(buttonLink, activeSite)}`} href={buttonHref}>
										{buttonDescription}
									</ButtonCircle>
								</StyledButtonWrapper>
							)}
						</Fade>
					</StyledModuleWrapper>
				</StyledBackgroundWrapper>
			</StyledBackgroundColor>
		</PageContainer>
	);
};

export default PhotoDescriptionButton;
