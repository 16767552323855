/* eslint-disable */

import React, { Component, useEffect, useState } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import PageContainer from '../common/PageContainer';
import TextHeader3 from '../typography/TextHeader3';
import TextNews from '../typography/TextNews';
import ButtonRectangle from '../common/ButtonRectangle';

import { isMobile } from 'react-device-detect';
import { Link } from 'gatsby';
import getSlug from '../../utils/getSlug';
import getUri from '../../utils/getUri';
import getDateInProperFormat from '../../utils/getDateInProperFormat';

const NEWS_CONTENT = 'news-content';

const StyledModuleWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding-bottom: 160px !important;
`;

const StyledContentWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	margin-bottom: 85px;
	margin-top: 150px;
	width: 100%;
	@media ${({ theme }) => theme.device.w_1024} {
		margin-bottom: 150px;
	}
`;

const NewsData = styled.p`
	font-size: 14px;
	//line-height: 26px;
	color: ${({ theme }) => theme.colors.greyDark};
	@media ${({ theme }) => theme.device.w_1024} {
		font-size: 16px;
		line-height: 29px;
	}
`;

const StyledNewsBox = styled.div`
	align-items: flex-start;
	border-bottom: 0.5px solid;
	border-bottom-color: ${({ theme }) => theme.colors.greyDarkLine};
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(3fr);
	justify-content: space-between;
	padding: 50px 8px 50px;
	transition: all 0.3s;
	&:hover {
		time {
			color: ${({ theme }) => theme.colors.green};
		}
		p {
			font-weight: ${({ theme }) => theme.fontWeight.regular};
		}
	}

	@media ${({ theme }) => theme.device.w_768} {
		padding: 50px 20px 50px 0px;
		grid-gap: 20px;
	}
	@media ${({ theme }) => theme.device.w_1024} {
		//grid-template-columns: 1fr 4fr 6fr;
		//grid-template-rows: 1fr;
		padding: 50px 20px 90px 0px;
		&:hover {
			padding: 80px 20px 130px 0px;
		}
	}
	@media ${({ theme }) => theme.device.w_1440} {
		grid-template-columns: 1fr 4fr 6fr;
		grid-template-rows: 1fr;
		padding: 50px 80px 120px 0px;
		grid-gap: 40px;
		&:hover {
			//box-shadow: 0 40px 44px -63px rgb(4 233 157 / 90%);
			padding: 80px 80px 130px 0px;
		}
	}

	.text-description {
		@media ${({ theme }) => theme.device.w_768} {
			width: 83%;
		}
		@media ${({ theme }) => theme.device.w_1440} {
			width: 100%;
		}
		@media ${({ theme }) => theme.device.w_1920} {
			padding-left: 8%;
		}
		@media ${({ theme }) => theme.device.w_2560} {
			padding-left: 12%;
		}
	}
	.text-header {
		position: relative;
		width: 80%;

		@media ${({ theme }) => theme.device.w_768} {
			width: 55%;
			top: -7px;
		}

		@media ${({ theme }) => theme.device.w_1440} {
			width: 100%;
		}
	}
`;

const StyledTextCircleWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const StyledCircle = styled.div`
	min-width: 45px;
	height: 45px;
	border: 1px solid ${({ theme }) => theme.colors.green};
	border-radius: 100px;
	position: relative;
	display: flex;
	// @media ${({ theme }) => theme.device.w_425} {
	// 	display: flex;
	// }

	@media ${({ theme }) => theme.device.w_1024} {
		min-width: 60px;
		height: 60px;
	}

	@media ${({ theme }) => theme.device.w_1440} {
		display: none;
	}
	&::after {
		content: '';
		position: absolute;
		width: 20px;
		background-color: var(--text-color);
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		height: 1px;
		margin: auto;
		@media ${({ theme }) => theme.device.w_1024} {
			width: 25px;
		}
	}
	&::before {
		content: '';
		position: absolute;
		width: 1px;
		background-color: var(--text-color);
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		height: 20px;
		margin: auto;
		@media ${({ theme }) => theme.device.w_1024} {
			height: 25px;
		}
	}
`;

const News = ({ data, activeSite }) => {
	const [customCursor, setCustomCursor] = useState(' ');
	// const [isMobileDevice, setIsMobileDevice];
	const moduleData = data?.modulWizytowkiAktualnosci || [];
	const moduleId = data?.remoteId + data?.__typename || '';

	let newsArray = [];
	let buttonLink = '';
	let buttonText = '';

	moduleData.map((item) => {
		if (item.__typename === 'Craft_modulWizytowkiAktualnosci_wybierzAktualnosci_BlockType') {
			const dataArray = item?.wybierzAktualnosc || '';
			dataArray.map((item) => {
				newsArray.push({
					title: item?.wizytowkaAktualnosciTytul || '',
					description: item?.wizytowkaAktualnosciOpis || '',
					date: item?.postDate || '',
					slug: item?.slug || '',
					uri: item?.uri || '',
				});
			});
		}
		if (item.__typename === 'Craft_modulWizytowkiAktualnosci_przycisk_BlockType') {
			buttonLink = item?.przyciskLink?.[0]?.slug || '';
			buttonText = item?.przyciskTekst || '';
		}
	});
	useEffect(() => {
		const getCustomCursor = document.getElementById('custom-cursor-news');
		setCustomCursor(getCustomCursor);
	});

	const handleMouseMoveOnSlider = (e) => {
		// const isMobileDevice = false;
		// const { isMobileDevice } = this.state;
		const offsetX = e.clientX - 34;
		const offsetY = e.clientY - 34;
		customCursor.style.top = `${offsetY}px`;
		customCursor.style.left = `${offsetX}px`;
	};

	return (
		<PageContainer className="full-width">
			<StyledModuleWrapper
				id={moduleId}
				// onMouseDown={(e) => handleMouseDownOnSlider(e)}
				// onMouseUp={(e) => handleMouseUpOnSlider(e)}
				className="inner-wrapper-medium news"
			>
				<StyledContentWrapper data-cursor={'green-circle'} id={NEWS_CONTENT} onMouseMove={(e) => handleMouseMoveOnSlider(e)}>
					{newsArray.map((item) => {
						const title = item?.title || '';
						const description = item?.description || '';
						const date = item?.date || '';
						const slug = item?.slug || '';
						const uri = item?.uri || '';
						const newDate = getDateInProperFormat(activeSite, date);
						return (
							<Fade bottom key={title}>
								<StyledNewsBox
									className="custom-cursor-animation custom-slider"
									as={Link}
									to={`/${getUri(uri, activeSite)}`}
								>
									<NewsData>{newDate}</NewsData>
									<StyledTextCircleWrapper>
										<TextHeader3 className="change-color text-header news">{title}</TextHeader3>
										<StyledCircle />
									</StyledTextCircleWrapper>
									<TextNews className="change-color text-description">{description}</TextNews>
								</StyledNewsBox>
							</Fade>
						);
					})}
				</StyledContentWrapper>
				<ButtonRectangle className="change-color" to={`/${getSlug(buttonLink, activeSite)}`}>
					{buttonText}
				</ButtonRectangle>
			</StyledModuleWrapper>
		</PageContainer>
	);
};

export default News;
