/* eslint-disable */
import React, { Component } from 'react';
import styled from 'styled-components';
import { navigate } from 'gatsby';
import TextHeader2 from '../typography/TextHeader2';
import TextDescription2 from '../typography/TextDescription2';
import getSlug from '../../utils/getSlug';

const StyledCategoryCardWrapper = styled.div`
	//margin: 20px 70px 20px 0;
	//width: 440px;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin: 20px 25px;
	@media (min-width: 768px) {
		margin: 20px 40px;
	}
	@media (min-width: 1024px) {
		margin: 20px 50px;
	}
	@media (min-width: 1440px) {
		margin: 20px 70px;
	}
`;

const StyledTextBelowImageWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
	@media (min-width: 1024px) {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const StyledNumberWrapper = styled.div`
	width: 100%;
	@media (min-width: 1024px) {
		width: 65px;
	}
`;

const StyledDescriptionWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	min-height: 240px;
	width: 100%;
	@media (min-width: 1024px) {
		width: calc(100% - 65px);
	}
`;

const StyledImageWrapper = styled.div`
	background-image: url(${({ bgImg }) => bgImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 50%;
	filter: ${({ isActiveSlide }) => (isActiveSlide ? 'grayscale(0)' : 'grayscale(100%)')};
	height: 200px;
	margin-bottom: 20px;
	overflow: hidden;
	position: relative;
	transition: all 0.3s ease-in-out;
	width: 200px;
	@media (min-width: 768px) {
		width: 280px;
		height: 280px;
		margin-bottom: 50px;
	}
	@media (min-width: 1024px) {
		width: 400px;
		height: 400px;
		margin-bottom: 80px;
	}
	@media (min-width: 1440px) {
		width: 530px;
		height: 530px;
		margin-bottom: 80px;
	}
`;

const StyledTextHeader2Wrapper = styled.div`
	max-width: 440px;
	min-height: 80px;
	@media (min-height: 768px) {
		min-height: 90px;
	}
	@media (min-width: 1440px) {
		max-width: 320px;
	}
`;

class SingleCategoryCard extends Component {
	handleSingleSlideClick(wasContentScrolled, linkToNavigate) {
		if (!wasContentScrolled) {
			navigate(linkToNavigate);
		}
	}

	render() {
		const {
			categoryName,
			categoryDescription,
			categoryImage,
			orderNumber,
			activeSlide,
			uri,
			activeSite,
			wasContentScrolled,
			isOnlyOneProductInCategory,
			onlyProductUri,
		} = this.props;
		const isActiveSlide = activeSlide === orderNumber;
		const slugToNavigate = isOnlyOneProductInCategory ? `/${getSlug(onlyProductUri, activeSite)}` : `/${getSlug(uri, activeSite)}`;

		return (
			<StyledCategoryCardWrapper
				slug={slugToNavigate}
				onClick={this.handleSingleSlideClick.bind(this, wasContentScrolled, slugToNavigate)}
				// onMouseOver={this.toggleCardIsHovered}
				// onMouseOut={this.toggleCardIsHovered}
				className="category-card"
			>
				<StyledImageWrapper isActiveSlide={isActiveSlide} bgImg={categoryImage} />
				<StyledTextBelowImageWrapper>
					<StyledNumberWrapper>
						<TextHeader2>{`_${orderNumber}`}</TextHeader2>
					</StyledNumberWrapper>
					<StyledDescriptionWrapper>
						<StyledTextHeader2Wrapper>
							<TextHeader2>{categoryName}</TextHeader2>
						</StyledTextHeader2Wrapper>
						<TextDescription2 className="mb-50">{categoryDescription}</TextDescription2>
					</StyledDescriptionWrapper>
				</StyledTextBelowImageWrapper>
			</StyledCategoryCardWrapper>
		);
	}
}

export default SingleCategoryCard;
