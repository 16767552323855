import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	color: black;
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 10px;
	font-weight: ${({ theme }) => theme.fontWeight.black};
	line-height: 12px;

	@media (min-width: 500px) {
		font-size: 12px;
		line-height: 20px;
	}

	@media ${({ theme }) => theme.device.w_768} {
		font-size: 12px;
		line-height: 28px;
	}

	@media (min-width: 1200px) {
		font-size: 16px;
		line-height: 36px;
	}

	@media ${({ theme }) => theme.device.w_1920} {
		font-size: 17px;
		line-height: 50px;
	}
	&.product {
		@media ${({ theme }) => theme.device.w_1920} {
			font-size: 17px;
			line-height: 50px;
		}
	}
`;

const TextSubpageHeadlineSmall = ({ className, children }) => {
	return <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextSubpageHeadlineSmall;
