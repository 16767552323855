/* eslint-disable */
import React, { Component } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { isMobile } from 'react-device-detect';
import PageContainer from '../common/PageContainer';
import WhiteCircleWithDot from '../../images/common/white-circle-narrow.svg';
import TextHeader2 from '../typography/TextHeader2';
import TextDescription2 from '../typography/TextDescription2';
import getSlug from '../../utils/getSlug';

const StyledFixedContentWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	left: 0;
	position: relative;
	top: 0;
	transition: all 1s ease-out;
	width: 100%;
	z-index: 2;
	&.sticky {
		background-color: transparent;
		position: fixed;
	}
	&.absolute {
		background-color: transparent;
		left: 0;
		position: absolute;
		top: 0;
	}
	@media (min-width: 1280px) {
		flex-direction: row;
		justify-content: space-between;
	}
`;

const StyledIndexAndTextWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-bottom: 50px;
	width: 100%;
	&.tablet {
		position: absolute;
		top: -100px;
		width: calc(100vw - 40px);
		left: calc((100vw - 40px - 230px) * (-1 / 2));
		//left: -85px;
		@media (min-width: 425px) {
			left: -85px;
			width: 400px;
		}
		@media (min-width: 768px) {
			left: -35px;
		}
		@media (min-width: 600px) {
			top: -200px;
		}
		//background-color: rosybrown;
	}
	&.tablet-sticky {
		//background-color: darkorange;
	}
	&.desktop {
		//display: ${({ isMobile }) => (isMobile ? 'none' : 'flex')};
		display: flex;
		//background-color: red;
		//background-color: ${({ isMobile }) => (isMobile ? 'darkblue' : 'red')};
	}
	&.sticky-mobile {
		position: absolute;
		top: -100px;
		width: calc(100vw - 40px);
		left: calc((100vw - 40px - 230px) * (-1 / 2));
		@media (min-width: 425px) {
			left: -85px;
			width: 400px;
		}
		@media (min-width: 768px) {
			left: -35px;
		}
		@media (min-width: 600px) {
			top: -200px;
		}
		//background-color: darkcyan;
		//margin-bottom: 0;
	}
	@media (min-width: 768px) {
		margin-bottom: 100px;
		max-width: 400px;
	}
	@media (min-width: 1280px) {
		margin-bottom: 0;
		width: calc((100% - 280px) / 2);
		padding-right: 50px;
		max-width: unset;
	}
	@media (min-width: 1440px) {
		width: calc((100% - 400px) / 2);
	}
	@media (min-width: 1920px) {
		width: calc((100% - 550px) / 2);
	}
	&.hidden {
		//visibility: hidden;
		display: none;
	}
`;

const StyledHeaderAndDescriptionWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: calc(100% - 60px);
`;

const StyledSliderWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	width: 100%;
`;

const StyledSingleSlide = styled.div`
	//background-color: red;
	align-items: center;
	background-color: ${({ theme }) => theme.colors.black};
	display: flex;
	flex-direction: column;
	height: 100vh;
	justify-content: center;
	position: relative;
	width: 100%;
	//border-bottom: 1px solid mediumvioletred;
`;

const StyledImageWrapper = styled.div`
	background-image: url(${({ bgImg }) => bgImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	border-radius: 50%;
	height: 250px;
	overflow: hidden;
	position: relative;
	transition: all 0.3s ease-in-out;
	width: 250px;
	@media (min-width: 768px) {
		width: 350px;
		height: 350px;
	}
	@media (min-width: 1024px) {
		width: 400px;
		height: 400px;
	}
	@media (min-width: 1280px) {
		width: 280px;
		height: 280px;
	}
	@media (min-width: 1440px) {
		width: 400px;
		height: 400px;
	}
	@media (min-width: 1920px) {
		width: 550px;
		height: 550px;
	}
`;

const StyledCircleWrapper = styled.img`
	transform: rotate(${({ rotationAngle }) => rotationAngle}deg);
	transition: transform 0s ease-in;
	width: 230px;
	@media (min-width: 768px) {
		width: 330px;
	}
	@media (min-width: 1024px) {
		width: 380px;
	}
	@media (min-width: 1280px) {
		width: 260px;
	}
	@media (min-width: 1440px) {
		width: 380px;
	}
	@media (min-width: 1920px) {
		width: 520px;
	}
`;

const StyledProgressBarWrapper = styled.div`
	display: none;
	flex-direction: column;
	justify-content: center;
	@media (min-width: 1024px) {
		width: calc((100% - 280px) / 2);
		padding-left: 50px;
	}
	@media (min-width: 1280px) {
		display: flex;
		width: calc((100% - 280px) / 2);
	}
	@media (min-width: 1440px) {
		width: calc((100% - 400px) / 2);
	}
	@media (min-width: 1920px) {
		width: calc((100% - 550px) / 2);
	}
`;

const StyledProgressBar = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	height: 2px;
	position: relative;
	transition: all 0.3s ease-in-out;
	width: 100px;
	&:before {
		background-color: ${({ theme }) => theme.colors.green};
		box-shadow: ${({ theme }) => theme.colors.green} 0 0 7px;
		content: '';
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		transition: all 0.3s ease-in-out;
		width: ${({ progress }) => `${progress}%`};
	}
`;

const StyledDiv = styled(Link)`
	position: relative;
`;

const StyledSimpleDiv = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const StyledFixedWrapper = styled.div`
	position: fixed;
	z-index: 100000;
	top: 100px;
	left: 100px;
	background-color: darkslateblue;
	color: honeydew;
`;
class ProductsVerticalSlider extends Component {
	state = {
		isSticky: false,
		circleRotationAngle: 0,
		currentSlideId: 0,
		isStickyItemsVisible: true,
		isAbsolute: false,
		isTextGreen: false,
		isSliderFixed: false,
	};

	initialAngle = 0;

	moduleOffset = 0;

	moduleHeight = 0;

	slide0Offset = 0;

	slide1Offset = 0;

	slide2Offset = 0;

	slide3Offset = 0;

	slide4Offset = 0;

	slide5Offset = 0;

	scrolledY = 0;

	progress = 0;

	endSliderTrigger = 0;

	customCursor = '';

	constructor(props) {
		super(props);
		this.actionsOnScroll = this.actionsOnScroll.bind(this);
	}

	componentDidMount() {
		setTimeout(() => {
			if (!isMobile) {
				this.customCursor = document.getElementById('custom-cursor-vertical-slider');
			}
		}, 0);
		const element = document.getElementById('vertical-slider');
		const screenHeight = window.innerHeight;
		const screenWidth = window.innerWidth;
		this.moduleOffset = element.offsetTop;
		this.moduleHeight = element.offsetHeight - 2 * screenHeight;
		const angle = Math.atan(screenHeight / screenWidth);
		const pi = Math.PI;
		const angleInDeg = angle * (180 / pi);
		this.initialAngle = -angleInDeg + 360 + 115;
		window.addEventListener('scroll', this.actionsOnScroll);
		// window.addEventListener('resize', this.actionsOnScroll);
		const vh = window.innerHeight * 0.01;
		document.documentElement.style.setProperty('--vh', `${vh}px`);
		const slide0 = document.getElementById('slide-0');
		const slide1 = document.getElementById('slide-1');
		const slide2 = document.getElementById('slide-2');
		const slide3 = document.getElementById('slide-3');
		const slide4 = document.getElementById('slide-4');
		const slide5 = document.getElementById('slide-5');
		this.slide0Offset = slide0.offsetTop - 100 * vh - 60 - 10 * vh;
		this.slide1Offset = slide1.offsetTop - 100 * vh - 60 - 10 * vh;
		this.slide2Offset = slide2.offsetTop - 100 * vh - 60 - 10 * vh;
		this.slide3Offset = slide3.offsetTop - 100 * vh - 60 - 10 * vh;
		this.slide4Offset = slide4.offsetTop - 100 * vh - 60 - 10 * vh;
		this.slide5Offset = slide5.offsetTop - 100 * vh - 60 - 10 * vh;
		this.endSliderTrigger = this.slide5Offset + 10 * vh;
		// const slide0 = document.getElementById('slide-0');
		// const slide1 = document.getElementById('slide-1');
		// const slide2 = document.getElementById('slide-2');
		// const slide3 = document.getElementById('slide-3');
		// const slide4 = document.getElementById('slide-4');
		// const slide5 = document.getElementById('slide-5');
		// this.slide0Offset = slide0.offsetTop - screenHeight;
		// this.slide1Offset = slide1.offsetTop - screenHeight;
		// this.slide2Offset = slide2.offsetTop - screenHeight;
		// this.slide3Offset = slide3.offsetTop - screenHeight;
		// this.slide4Offset = slide4.offsetTop - screenHeight;
		// this.slide5Offset = slide5.offsetTop - screenHeight;
		this.setState({ circleRotationAngle: this.initialAngle });
		// this.endSliderTrigger = this.slide5Offset;
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.actionsOnScroll);
		// window.removeEventListener('resize', this.actionsOnScroll);
	}

	// calculateScreenHeightAndSlidesOffset = () => {
	// 	const vh = window.innerHeight * 0.01;
	// 	document.documentElement.style.setProperty('--vh', `${vh}px`);
	// 	// const slide0 = document.getElementById('slide-0');
	// 	const slide1 = document.getElementById('slide-1');
	// 	const slide2 = document.getElementById('slide-2');
	// 	const slide3 = document.getElementById('slide-3');
	// 	const slide4 = document.getElementById('slide-4');
	// 	const slide5 = document.getElementById('slide-5');
	// 	// this.slide0Offset = slide0.offsetTop - 100 * vh;
	// 	this.slide1Offset = slide1.offsetTop - 100 * vh;
	// 	this.slide2Offset = slide2.offsetTop - 100 * vh;
	// 	this.slide3Offset = slide3.offsetTop - 100 * vh;
	// 	this.slide4Offset = slide4.offsetTop - 100 * vh;
	// 	this.slide5Offset = slide5.offsetTop - 100 * vh;
	// 	this.endSliderTrigger = this.slide5Offset;
	// };

	handleScrollPosition() {
		if (window.scrollY >= this.moduleOffset && window.scrollY < this.moduleOffset + this.moduleHeight - window.innerHeight) {
			this.setState({ isSticky: true });
		} else if (window.scrollY < this.moduleOffset) {
			this.setState({ isSticky: false });
		}
	}

	handleCircleRotation() {
		const pixelsInsideModuleScrolled = window.scrollY - this.moduleOffset;
		if (window.scrollY > this.moduleOffset) {
			const scrolledPartRatio = pixelsInsideModuleScrolled / this.moduleHeight;
			this.progress = scrolledPartRatio * 100;
			const rotationAngle = 360 * scrolledPartRatio;
			this.setState({ circleRotationAngle: this.initialAngle + rotationAngle });
		}
	}

	handleCurrentSlide() {
		this.scrolledY = window.scrollY;
		if (window.scrollY < this.slide0Offset) {
			this.setState({ currentSlideId: 0 });
			this.setState({ isSliderFixed: false });
		} else if (window.scrollY >= this.slide0Offset && window.scrollY < this.slide1Offset) {
			this.setState({ currentSlideId: 0 });
			this.setState({ isSliderFixed: true });
		} else if (window.scrollY >= this.slide1Offset && window.scrollY < this.slide2Offset) {
			// alert(this.slide0Offset, this.slide1Offset, this.slide2Offset, this.slide3Offset, this.slide4Offset, this.slide5Offset);
			this.setState({ currentSlideId: 1 });
		} else if (window.scrollY >= this.slide2Offset && window.scrollY < this.slide3Offset) {
			this.setState({ currentSlideId: 2 });
		} else if (window.scrollY >= this.slide3Offset && window.scrollY < this.slide4Offset) {
			this.setState({ currentSlideId: 3 });
		} else if (window.scrollY >= this.slide4Offset && window.scrollY < this.slide5Offset) {
			this.setState({ currentSlideId: 4 });
			this.setState({ isStickyItemsVisible: true });
		} else if (window.scrollY >= this.slide5Offset && window.scrollY < this.slide5Offset + window.innerHeight * 0.2) {
			this.setState({ currentSlideId: 5 });
			this.setState({ isSticky: true });
			this.setState({ isStickyItemsVisible: true });
		} else if (window.scrollY >= this.slide5Offset + window.innerHeight * 0.2) {
			this.setState({ isSticky: false });
			this.setState({ isStickyItemsVisible: false });
			this.setState({ isSliderFixed: false });
		}
	}

	handleMouseMoveOnSlider = (e) => {
		const offsetX = e.clientX - 34;
		const offsetY = e.clientY - 34;
		this.customCursor.style.top = `${offsetY}px`;
		this.customCursor.style.left = `${offsetX}px`;
	};

	handleMouseOverSlide = () => {
		this.setState({ isTextGreen: true });
	};

	handleMouseOutSlide = () => {
		this.setState({ isTextGreen: false });
	};

	actionsOnScroll() {
		if (window.scrollY >= this.endSliderTrigger) {
			this.setState({ isAbsolute: true });
		} else {
			this.setState({ isAbsolute: false });
		}
		// this.calculateScreenHeightAndSlidesOffset();
		this.handleScrollPosition();
		this.handleCircleRotation();
		this.handleCurrentSlide();
	}

	render() {
		const { data, activeSite } = this.props;
		const { isSticky, isStickyItemsVisible, isTextGreen, isAbsolute, currentSlideId, isSliderFixed, circleRotationAngle } = this.state;
		const productsSlides = [];
		const productsSliderData = data?.modulSliderNaPodstronieProdukty || [];
		productsSliderData.forEach((item) => {
			if (item.__typename === 'Craft_modulSliderNaPodstronieProdukty_slajdPoczatkowy_BlockType') {
				productsSlides.push({
					header: item?.naglowek || '',
					description: item?.opis || '',
					imageUrl: '',
					slug: '',
				});
			} else if (item.__typename === 'Craft_modulSliderNaPodstronieProdukty_kategorieProduktow_BlockType') {
				const chooseProductsCategory = item?.wybierzKategorie || [];
				chooseProductsCategory.forEach((el) => {
					productsSlides.push({
						header: el?.wizytowkaNazwa || '',
						description: el?.wizytowkaOpis || '',
						imageUrl: el?.wizytowkaZdjecie?.[0]?.url || '',
						slug: el?.slug || '',
						uri: el?.uri || '',
						isOnlyOneProductInCategory: el?.remoteChildren?.length === 1 || false,
						onlyProductUri: el?.remoteChildren?.[0]?.uri || '',
					});
				});
			}
		});

		return (
			<PageContainer className="black" id="vertical-slider">
				{/*<StyledFixedWrapper>*/}
				{/*	{this.slide0Offset +*/}
				{/*		' ' +*/}
				{/*		this.slide1Offset +*/}
				{/*		' ' +*/}
				{/*		this.slide2Offset +*/}
				{/*		' ' +*/}
				{/*		this.slide3Offset +*/}
				{/*		' ' +*/}
				{/*		this.slide4Offset +*/}
				{/*		' ' +*/}
				{/*		this.slide5Offset}*/}
				{/*</StyledFixedWrapper>*/}
				{isStickyItemsVisible && !isAbsolute && (
					<StyledFixedContentWrapper
						isAbsolute={isAbsolute}
						className={isSticky ? 'sticky inner-wrapper-large' : 'inner-wrapper-large'}
					>
						{currentSlideId === 0 && (
							<StyledIndexAndTextWrapper
								className={isSliderFixed && !isMobile ? 'desktop' : isSliderFixed && isMobile ? 'hidden' : ''}
							>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
									<TextHeader2
										className={isTextGreen ? 'green' : 'white'}
										style={{ width: '60px' }}
									>{`_${currentSlideId}`}</TextHeader2>
									<StyledHeaderAndDescriptionWrapper
									// as={Link}
									// to={`/${getSlug(productsSlides[currentSlideId].uri, activeSite)}`}
									>
										<TextHeader2 className={isTextGreen ? 'green' : 'white'} style={{ marginBottom: '10px' }}>
											{productsSlides[currentSlideId].header}
										</TextHeader2>
										<div>
											<TextDescription2 className="white">
												{productsSlides[currentSlideId].description}
											</TextDescription2>
										</div>
									</StyledHeaderAndDescriptionWrapper>
								</div>
							</StyledIndexAndTextWrapper>
						)}
						{currentSlideId > 0 && (
							<StyledIndexAndTextWrapper
								id="vertical-slider"
								data-cursor="green-circle-vertical-slider"
								className={!isMobile ? 'custom-cursor-animation custom-slider desktop' : 'hidden'}
								onMouseMove={this.handleMouseMoveOnSlider}
								onMouseOver={this.handleMouseOverSlide}
								onMouseOut={this.handleMouseOutSlide}
							>
								<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
									<TextHeader2
										className={isTextGreen ? 'green' : 'white'}
										style={{ width: '60px' }}
									>{`_${currentSlideId}`}</TextHeader2>
									<StyledHeaderAndDescriptionWrapper
										as={Link}
										to={
											productsSlides[currentSlideId].isOnlyOneProductInCategory
												? `/${getSlug(productsSlides[currentSlideId].onlyProductUri, activeSite)}`
												: `/${getSlug(productsSlides[currentSlideId].uri, activeSite)}`
										}
									>
										<TextHeader2 className={isTextGreen ? 'green' : 'white'} style={{ marginBottom: '10px' }}>
											{productsSlides[currentSlideId].header}
										</TextHeader2>
										<div>
											<TextDescription2 className="white">
												{productsSlides[currentSlideId].description}
											</TextDescription2>
										</div>
									</StyledHeaderAndDescriptionWrapper>
								</div>
							</StyledIndexAndTextWrapper>
						)}
						{currentSlideId === 0 && (
							<StyledSimpleDiv onMouseOver={this.handleMouseOverSlide} onMouseOut={this.handleMouseOutSlide}>
								<StyledIndexAndTextWrapper className={isMobile && isSliderFixed ? 'tablet' : 'hidden'}>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
										<TextHeader2
											className={isTextGreen ? 'green' : 'white'}
											style={{ width: '60px' }}
										>{`_${currentSlideId}`}</TextHeader2>
										<StyledHeaderAndDescriptionWrapper
										// as={Link}
										// to={`/${getSlug(productsSlides[currentSlideId].uri, activeSite)}`}
										>
											<TextHeader2 className={isTextGreen ? 'green' : 'white'} style={{ marginBottom: '10px' }}>
												{productsSlides[currentSlideId].header}
											</TextHeader2>
											<div>
												<TextDescription2 className="white">
													{productsSlides[currentSlideId].description}
												</TextDescription2>
											</div>
										</StyledHeaderAndDescriptionWrapper>
									</div>
								</StyledIndexAndTextWrapper>
								<StyledCircleWrapper rotationAngle={circleRotationAngle} src={WhiteCircleWithDot} />
							</StyledSimpleDiv>
						)}
						{currentSlideId > 0 && (
							<StyledDiv
								id="vertical-slider"
								data-cursor="green-circle-vertical-slider"
								className="custom-cursor-animation custom-slider"
								onMouseMove={this.handleMouseMoveOnSlider}
								onMouseOver={this.handleMouseOverSlide}
								onMouseOut={this.handleMouseOutSlide}
								as={Link}
								to={
									productsSlides[currentSlideId].isOnlyOneProductInCategory
										? `/${getSlug(productsSlides[currentSlideId].onlyProductUri, activeSite)}`
										: `/${getSlug(productsSlides[currentSlideId].uri, activeSite)}`
								}
							>
								<StyledIndexAndTextWrapper
									isMobile={isMobile}
									id="vertical-slider"
									data-cursor="green-circle-vertical-slider"
									className={isMobile ? 'tablet' : 'hidden'}
									onMouseMove={this.handleMouseMoveOnSlider}
									onMouseOver={this.handleMouseOverSlide}
									onMouseOut={this.handleMouseOutSlide}
								>
									<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
										<TextHeader2
											className={isTextGreen ? 'green' : 'white'}
											style={{ width: '60px' }}
										>{`_${currentSlideId}`}</TextHeader2>
										<StyledHeaderAndDescriptionWrapper
											as={Link}
											to={
												productsSlides[currentSlideId].isOnlyOneProductInCategory
													? `/${getSlug(productsSlides[currentSlideId].onlyProductUri, activeSite)}`
													: `/${getSlug(productsSlides[currentSlideId].uri, activeSite)}`
											}
										>
											<TextHeader2 className={isTextGreen ? 'green' : 'white'} style={{ marginBottom: '10px' }}>
												{productsSlides[currentSlideId].header}
											</TextHeader2>
											<div>
												<TextDescription2 className="white">
													{productsSlides[currentSlideId].description}
												</TextDescription2>
											</div>
										</StyledHeaderAndDescriptionWrapper>
									</div>
								</StyledIndexAndTextWrapper>
								<StyledCircleWrapper rotationAngle={circleRotationAngle} src={WhiteCircleWithDot} />
							</StyledDiv>
						)}
						<StyledProgressBarWrapper>
							<StyledProgressBar progress={this.progress} />
						</StyledProgressBarWrapper>
					</StyledFixedContentWrapper>
				)}
				<StyledSliderWrapper id="product-slider">
					{productsSlides.map((item, key) => {
						const imageUrl = item?.imageUrl || '';
						// const numberOfSlides = productsSlides.length;
						const slideId = `slide-${key}`;
						return (
							<StyledSingleSlide key={slideId} id={slideId}>
								<StyledImageWrapper bgImg={imageUrl} />
								{isAbsolute && (
									<StyledFixedContentWrapper
										className={isAbsolute ? 'absolute inner-wrapper-large' : 'inner-wrapper-large'}
									>
										<StyledIndexAndTextWrapper
											id="vertical-slider"
											data-cursor="green-circle-vertical-slider"
											className={isMobile ? 'hidden' : 'custom-cursor-animation custom-slider'}
											onMouseMove={this.handleMouseMoveOnSlider}
											onMouseOver={this.handleMouseOverSlide}
											onMouseOut={this.handleMouseOutSlide}
										>
											<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
												<TextHeader2
													className={isTextGreen ? 'green' : 'white'}
													style={{ width: '60px' }}
												>{`_${currentSlideId}`}</TextHeader2>
												<StyledHeaderAndDescriptionWrapper
													as={Link}
													to={
														productsSlides[currentSlideId].isOnlyOneProductInCategory
															? `/${getSlug(productsSlides[currentSlideId].onlyProductUri, activeSite)}`
															: `/${getSlug(productsSlides[currentSlideId].uri, activeSite)}`
													}
												>
													<TextHeader2
														className={isTextGreen ? 'green' : 'white'}
														style={{ marginBottom: '10px' }}
													>
														{productsSlides[currentSlideId].header}
													</TextHeader2>
													<div>
														<TextDescription2 className="white">
															{productsSlides[currentSlideId].description}
														</TextDescription2>
													</div>
												</StyledHeaderAndDescriptionWrapper>
											</div>
										</StyledIndexAndTextWrapper>
										<StyledDiv
											id="vertical-slider"
											data-cursor="green-circle-vertical-slider"
											className="custom-cursor-animation custom-slider"
											onMouseMove={this.handleMouseMoveOnSlider}
											onMouseOver={this.handleMouseOverSlide}
											onMouseOut={this.handleMouseOutSlide}
											as={Link}
											to={
												productsSlides[currentSlideId].isOnlyOneProductInCategory
													? `/${getSlug(productsSlides[currentSlideId].onlyProductUri, activeSite)}`
													: `/${getSlug(productsSlides[currentSlideId].uri, activeSite)}`
											}
										>
											<StyledIndexAndTextWrapper
												id="vertical-slider"
												data-cursor="green-circle-vertical-slider"
												className={isMobile ? 'sticky-mobile' : 'hidden'}
												onMouseMove={this.handleMouseMoveOnSlider}
												onMouseOver={this.handleMouseOverSlide}
												onMouseOut={this.handleMouseOutSlide}
											>
												<div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
													<TextHeader2
														className={isTextGreen ? 'green' : 'white'}
														style={{ width: '60px' }}
													>{`_${currentSlideId}`}</TextHeader2>
													<StyledHeaderAndDescriptionWrapper
														as={Link}
														to={
															productsSlides[currentSlideId].isOnlyOneProductInCategory
																? `/${getSlug(productsSlides[currentSlideId].onlyProductUri, activeSite)}`
																: `/${getSlug(productsSlides[currentSlideId].uri, activeSite)}`
														}
													>
														<TextHeader2
															className={isTextGreen ? 'green' : 'white'}
															style={{ marginBottom: '10px' }}
														>
															{productsSlides[currentSlideId].header}
														</TextHeader2>
														<div>
															<TextDescription2 className="white">
																{productsSlides[currentSlideId].description}
															</TextDescription2>
														</div>
													</StyledHeaderAndDescriptionWrapper>
												</div>
											</StyledIndexAndTextWrapper>
											<StyledCircleWrapper rotationAngle={circleRotationAngle} src={WhiteCircleWithDot} />
										</StyledDiv>
										<StyledProgressBarWrapper>
											<StyledProgressBar progress={this.progress} />
										</StyledProgressBarWrapper>
									</StyledFixedContentWrapper>
								)}
							</StyledSingleSlide>
						);
					})}
				</StyledSliderWrapper>
			</PageContainer>
		);
	}
}

export default ProductsVerticalSlider;
