import React from 'react';
import styled from 'styled-components';

const StyledCheckboxLabel = styled.label`
	margin: 20px 0;

	p {
		color: black;
		font-size: 16px;
		line-height: 23px;
		margin: 0;
		padding-left: 42px;

		// @media (min-width: 1000px) {
		// 	font-size: ${({ theme }) => theme.fontSizes.f16};
		// 	line-height: ${({ theme }) => theme.lineHeight.line15};
		// 	padding-right: 10px;
		// }
		a {
			color: black;
			text-decoration: none;
		}
	}
`;

const StyledCheckboxInput = styled.div`
	align-items: center;
	display: flex;
	position: relative;

	.checkbox-square {
		height: 40px;
		margin-left: -8px;
		opacity: 0;
		position: absolute;
		top: 8px;
		width: 40px;
		z-index: 1;

		& + label {
			cursor: pointer;
			padding: 0;
			position: relative;
		}

		& + label:before {
			background: transparent;
			border: 1px solid black;
			//border-radius: 5px;
			content: '';
			height: 22px;
			margin-right: 10px;
			position: absolute;
			top: 0;
			vertical-align: text-top;
			width: 22px;
		}

		&:checked + label:before {
			background-color: ${({ theme }) => theme.colors.green};
		}

		& + label:after {
			background-color: ${({ theme }) => theme.colors.green};
			content: '';
			height: 6px;
			left: 8px;
			opacity: 0;
			position: absolute;
			top: 8px;
			//transition: opacity 0.4s ease;
			width: 6px;
		}

		&:checked + label:after {
			opacity: 1;
		}
	}
`;

const CheckboxInfo = (props) => {
	const { children, checkboxid } = props;
	const newProps = { ...props, children: null };

	return (
		<StyledCheckboxInput {...newProps}>
			<input className="checkbox-square" id={checkboxid || 'checkbox'} {...newProps} />
			<StyledCheckboxLabel htmlFor={checkboxid || 'checkbox'}>
				<div>{children}</div>
			</StyledCheckboxLabel>
		</StyledCheckboxInput>
	);
};

export default CheckboxInfo;
