import React, { Component } from 'react';
import styled, { keyframes } from 'styled-components';
import { isMobile } from 'react-device-detect';
import SingleCategoryCard from './SingleCategoryCard';
import ConcentricCircles from '../common/ConcentricCircles';

const SCROLLBAR_THUMB = 'scrollbar-thumb';
const SCROLL_CONTENT = 'scroll-content';
const GALLERY_CONTENT = 'gallery-content';

const StyledSliderWithScrollWrapper = styled.div`
	left: -25px;
	overflow-x: visible !important;
	overflow-y: visible !important;
	padding-left: 30px;
	position: relative;
	touch-action: manipulation;
	@media ${({ theme }) => theme.device.w_425} {
		padding-left: calc((100% - 60px) * 1 / 12 + 30px);
	}
	@media ${({ theme }) => theme.device.w_768} {
		left: -40px;
		padding-left: calc((100% - 124px) * 1 / 12 + 62px);
	}
	@media ${({ theme }) => theme.device.w_1024} {
		padding-left: calc((100% - 124px) * 1 / 12 + 62px);
		left: -50px;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		padding-left: calc((100% - 150px) * 1 / 12 + 75px);
		left: -70px;
	}
`;

const bgOpacity = (maxOpacity, maxScale) => keyframes`
  0% { opacity: 0; transform: scale(0.2);}
  100% {opacity: ${maxOpacity}; transform: scale(${maxScale});}
`;

const StyledCurrentSliderElementBorder = styled.div`
	// background-color: rgba(4, 233, 157, 0.05);
	// background-color: rgba(4, 233, 17, 0.5);
	// border-radius: 50%;
	//display: none;
	display: block;
	position: absolute;
	z-index: 1;
	pointer-events: none;
	opacity: 0;
	width: 300px;
	height: 300px;
	top: 70px;
	left: 45px;
	@media (min-width: 425px) {
		left: calc((100% - 40px) * 1 / 12 + 43px);
	}
	@media ${({ theme }) => theme.device.w_768} {
		left: calc((100% - 124px) * 1 / 12 + 62px - 10px);
		top: 30px;
		display: block;
		height: 380px;
		width: 380px;
	}
	@media ${({ theme }) => theme.device.w_1024} {
		height: 540px;
		width: 540px;
		//padding left na wrapperze + margin left całego slidera - różnica pomiedzy srednicami / 2
		left: calc((100% - 124px) * 1 / 12 + 62px + 50px - 70px);
		//margin top card + padding top scrolling content - różnica pomiedzy srednicami / 2
		top: calc(20px + 60px - 70px);
	}
	@media ${({ theme }) => theme.device.w_1440} {
		height: 800px;
		width: 800px;
		left: calc((100% - 150px) * 1 / 12 + 75px - 65px);
		top: calc(20px + 150px - 135px);
	}
	&:before {
		border: 1px solid ${({ theme }) => theme.colors.green};
		border-radius: 50%;
		content: '';
		height: 230px;
		left: -5px;
		position: absolute;
		top: -5px;
		width: 230px;
		opacity: 0;
		@media (min-width: 768px) {
			height: 320px;
			width: 320px;
			left: 30px;
			top: 30px;
		}
		@media (min-width: 1024px) {
			height: 450px;
			//roznica pomiedzy srednicami / 2
			left: 45px;
			top: calc((540px - 450px) / 2);
			width: 450px;
		}
		@media (min-width: 1440px) {
			height: 588px;
			left: 106px;
			top: calc((800px - 588px) / 2);
			width: 588px;
		}
	}
	&.product-slider {
		opacity: 1;
		&:before {
			opacity: 1;
			transition: opacity 0.3s ease-in-out;
		}
		div.bg-circle-3 {
			opacity: 0;
			transform: scale(0);
			animation: 0.4s normal forwards 1 ${bgOpacity(0.5, 3)} 0.2s ease-out;
		}

		div.bg-circle-2 {
			opacity: 0;
			transform: scale(0);
			animation: 0.3s normal forwards 1 ${bgOpacity(0.5, 2.21875)} 0.2s ease-out;
		}

		.bg-circle-1 {
			opacity: 0;
			transform: scale(0);
			animation: 0.2s normal forwards 1 ${bgOpacity(0.5, 1.6)} 0.2s ease-out;
		}
	}
`;

const StyledClientsSliderWrapper = styled.div`
	display: inline-flex;
	margin-bottom: 0;
	padding-top: 60px;
	position: relative;
	width: auto;
	@media (min-width: 1440px) {
		padding-top: 150px;
	}
`;

const StyledScrollbarPromptWrapper = styled.div`
	color: ${({ theme }) => theme.colors.green};
	display: flex;
	justify-content: center;
	padding-top: 0;
	position: relative;
	@media ${({ theme }) => theme.device.w_768} {
		padding-bottom: 0;
		bottom: 0;
		top: unset;
	}
`;

const StyledCustomScrollbar = styled.div`
	background-color: ${({ theme }) => theme.colors.greyDark};
	border-radius: 5px;
	bottom: 0;
	height: 6px;
	left: 20%;
	margin: 0 auto;
	opacity: 0;
	position: absolute;
	right: 0;
	width: 10%;
	z-index: 10;
	@media ${({ theme }) => theme.device.w_768} {
		margin: unset;
		top: unset;
	}
	&.fullWidth {
		@media ${({ theme }) => theme.device.w_1024} {
			width: 100%;
		}
	}
`;

const StyledScrollbarThumb = styled.div`
	background-color: ${({ theme }) => theme.colors.green};
	border-radius: 5px;
	height: 100%;
	opacity: 0;
	width: ${(props) => props.width};
	z-index: 11;
`;

class CustomSlider extends Component {
	state = {
		scrollThumbWidth: 0,
		activeSlideNumber: 1,
		isMobileDevice: false,
		wasContentScrolled: false,
	};

	scrollbarWidth;

	scrollContentWidth;

	slidesNumber;

	slideWidth;

	mouseDown = false;

	mouseDownOnSlider = false;

	scrollContentTransition = 0;

	scrollThumbTransition = 0;

	startScrollThumbTransition = 0;

	clickedPointXPosition = 0;

	centerPointOfScrollThumb = 0;

	scrollbarElement;

	scrollContentElement;

	scrollThumbElement;

	customCursor;

	sliderIntervalId;

	constructor(props) {
		super(props);
		this.setInitSizes = this.setInitSizes.bind(this);
		this.handleMouseMove = this.handleMouseMove.bind(this);
		this.handleMouseUp = this.handleMouseUp.bind(this);
		this.handleTouchMove = this.handleTouchMove.bind(this);
		this.handleTouchEnd = this.handleTouchEnd.bind(this);
	}

	componentDidMount() {
		const { isMobileDevice } = this.state;
		this.setState({ isMobileDevice: isMobile });
		if (!isMobileDevice) {
			setTimeout(() => {
				this.scrollContentElement = document.getElementById('scroll-content');
				this.scrollThumbElement = document.getElementById('scrollbar-thumb');
				this.customCursor = document.getElementById('custom-cursor-slider');
				this.galleryContentElement = document.getElementById(GALLERY_CONTENT);
				this.setInitSizes();
				if (typeof window !== 'undefined' && window.innerWidth > 780) {
					window.addEventListener('resize', this.setInitSizes);
				}
				window.addEventListener('mousemove', this.handleMouseMove);
				window.addEventListener('touchmove', this.handleTouchMove);
				window.addEventListener('click', this.handleMouseUp);
				window.addEventListener('touchend', this.handleTouchEnd);
				// TODO: temp place to start animation
				this.greenCirclesAnimation();
			}, 0);
			// this.sliderIntervalId = setInterval(this.setInitSizes, 100)
		}
	}

	// componentDidUpdate(prevProps) {
	// 	const { runAnimation } = this.props;
	// 	if (prevProps.runAnimation !== runAnimation) {
	// 		this.greenCirclesAnimation();
	// 	}
	// }

	componentWillUnmount() {
		if (typeof window !== 'undefined' && window.innerWidth > 780) {
			window.removeEventListener('resize', this.setInitSizes);
		}
		window.removeEventListener('mousemove', this.handleMouseMove);
		window.removeEventListener('touchmove', this.handleTouchMove);
		window.removeEventListener('click', this.handleMouseUp);
		window.removeEventListener('touchend', this.handleTouchEnd);
		clearInterval(this.sliderIntervalId);
	}

	handleScrollClick(e) {
		e.preventDefault();
		if (e.target.id !== 'scrollbar-thumb') {
			const { scrollThumbWidth } = this.state;
			const clickedPointOnScrollbar = e.clientX - this.scrollbarElement.left;
			const slideToScrollTo = Math.ceil(clickedPointOnScrollbar / scrollThumbWidth);
			this.transitionScrollThumb(slideToScrollTo);
			this.transitionScrollContent(slideToScrollTo);
		}
	}

	handleMouseDown = (e) => {
		e.preventDefault();
		if (e.target.id === 'scrollbar-thumb') {
			this.clickedPointXPosition = e.clientX;
			this.startScrollThumbTransition = this.scrollThumbTransition;
			this.mouseDown = true;
		}
	};

	handleTouchStart(e) {
		if (e.target.id === 'scrollbar-thumb') {
			this.clickedPointXPosition = e.touches[0].screenX;
			this.startScrollThumbTransition = this.scrollThumbTransition;
			this.mouseDown = true;
		}
	}

	handleTransition(maxTransition) {
		const { scrollThumbWidth } = this.state;
		if (this.newScrollContentTransition > 0) {
			this.newScrollContentTransition = 0;
		} else if (Math.abs(this.newScrollContentTransition) > maxTransition) {
			this.newScrollContentTransition = -maxTransition;
		}
		const percentageScrollContentShift = -(this.newScrollContentTransition / (this.scrollContentWidth - this.slideWidth)).toFixed(2);
		this.scrollThumbTransition = Math.round((this.scrollbarWidth - scrollThumbWidth) * percentageScrollContentShift);
		this.realTimeTransition(this.newScrollContentTransition);
	}

	handleMouseMove(e) {
		e.preventDefault();
		const { scrollThumbWidth } = this.state;
		if (this.scrollbarElement) {
			this.centerPointOfScrollThumb =
				this.scrollThumbElement.getBoundingClientRect().left + Math.floor(scrollThumbWidth / 2) - this.scrollbarElement.left;
		}
		if (this.mouseDown) {
			this.scrollThumbElement.style.transition = 'none';
			this.scrollContentElement.style.transition = 'none';
			this.translationOnMouseMove(e);
		} else if (this.mouseDownOnSlider) {
			this.scrollThumbElement.style.transition = 'none';
			this.scrollContentElement.style.transition = 'none';
			this.translationOnMouseMoveOnSlider(e);
		}
	}

	handleTouchMove(e) {
		e.preventDefault();
		const { scrollThumbWidth } = this.state;

		this.centerPointOfScrollThumb =
			this.scrollThumbElement.getBoundingClientRect().left + Math.floor(scrollThumbWidth / 2) - this.scrollbarElement.left;

		if (this.mouseDown) {
			this.scrollThumbElement.style.transition = 'none';
			this.scrollContentElement.style.transition = 'none';
			this.translationOnTouchMove(e);
		} else if (this.mouseDownOnSlider) {
			this.scrollThumbElement.style.transition = 'none';
			this.scrollContentElement.style.transition = 'none';
			this.translationOnTouchMoveOnSlider(e);
		}
	}

	handleMouseDownOnSlider = (e) => {
		e.preventDefault();
		this.setState({ wasContentScrolled: false });
		this.clickedPointXPosition = e.clientX;
		this.mouseDownOnSlider = true;
		this.customCursor.classList.add('mouse-down');
	};

	handleMouseUpOnSlider = (e) => {
		e.preventDefault();
		this.customCursor.classList.remove('mouse-down');
	};

	handleMouseMoveOnSlider = (e) => {
		const { isMobileDevice } = this.state;
		// this.customCursor.style.display = 'flex';
		// const rect = e.currentTarget.getBoundingClientRect();
		const offsetX = e.clientX - 34;
		const offsetY = e.clientY - 34;
		if (!isMobileDevice) {
			this.customCursor.style.top = `${offsetY}px`;
			this.customCursor.style.left = `${offsetX}px`;
		}
	};

	// handleMouseOutOnSlider = () => {
	// 	// this.customCursor.style.display = 'none';
	// };

	handleTouchStartOnSlider = (e) => {
		e.preventDefault();
		this.setState({ wasContentScrolled: false });
		this.clickedPointXPosition = e.touches[0].screenX;
		this.mouseDownOnSlider = true;
	};

	handleMouseUp() {
		this.goToNearestSlide();
	}

	handleTouchEnd() {
		this.goToNearestSlide();
	}

	setValuesForLastScroll = () => {
		const { fullWidth } = this.props;
		const galleryContentWidth = this.galleryContentElement.clientWidth;
		const numOfVisibleEl = galleryContentWidth / this.slideWidth;
		const numOfVisibleElCeil = Math.ceil(galleryContentWidth / this.slideWidth);
		this.lastShift = (numOfVisibleElCeil % numOfVisibleEl).toFixed(2);
		const lastShiftBoundary = fullWidth ? 0.1 : 0.25;

		if (this.lastShift <= lastShiftBoundary) {
			this.sliderShiftsNumber = this.slidesNumber + 1 - numOfVisibleElCeil;
			this.lastShift = 1;
		} else {
			this.sliderShiftsNumber = this.slidesNumber + 1 - numOfVisibleEl;
		}
	};

	setInitSizes() {
		const { scrollThumbWidth } = this.state;
		// setTimeout(() => {
		this.scrollContentWidth = this.scrollContentElement.scrollWidth;
		// }, 500);
		// setTimeout(() => {
		this.scrollbarElement = document.getElementById('scrollbar').getBoundingClientRect();
		this.setSlidePlusMarginWidth();
		this.setValuesForLastScroll();
		this.setScrollThumbWidth();
		this.setNewActiveSlide(1);
		this.newScrollContentTransition = 0;
		this.scrollContentElement.style.transform = 'translateX(0)';
		this.scrollThumbElement.style.transform = 'translateX(0)';
		this.scrollThumbTransition = scrollThumbWidth;
		this.startScrollThumbTransition = this.scrollThumbTransition;
		// }, 700);
	}

	setNewActiveSlide = (slideToScrollTo) => {
		this.setState({
			activeSlideNumber: slideToScrollTo,
		});
	};

	setScrollThumbWidth() {
		this.scrollbarWidth = document.getElementById('scrollbar').offsetWidth;
		this.setState({ scrollThumbWidth: this.scrollbarWidth / this.slidesNumber });
	}

	setSlidePlusMarginWidth() {
		this.slideWidth = this.scrollContentWidth / this.slidesNumber;
	}

	greenCirclesAnimation = () => {
		const greenCirclesWrapperElement = document.getElementById('green-circles-product-slider');
		greenCirclesWrapperElement.classList.add('product-slider');
	};

	transitionScrollThumb(slideToScrollTo) {
		this.setState({ activeSlideNumber: slideToScrollTo });
		const { scrollThumbWidth } = this.state;
		if (slideToScrollTo < 1) {
			this.scrollThumbTransition = 0;
		} else {
			this.scrollThumbTransition = (slideToScrollTo - 1) * scrollThumbWidth;
		}
		this.scrollThumbElement.style.transform = `translateX(${this.scrollThumbTransition}px)`;
		this.scrollThumbElement.style.transition = 'linear 200ms';
	}

	transitionScrollContent(slideToScrollTo) {
		this.scrollContentTransition = this.slideWidth * (slideToScrollTo - 1);
		this.scrollContentElement.style.transform = `translateX(-${this.scrollContentTransition}px)`;
		this.scrollContentElement.style.transition = 'linear 200ms';
	}

	goToNearestSlide() {
		if (this.mouseDown || this.mouseDownOnSlider) {
			const { scrollThumbWidth } = this.state;
			this.mouseDown = false;
			this.mouseDownOnSlider = false;
			this.centerPointOfScrollThumb =
				this.scrollThumbElement.getBoundingClientRect().left + Math.floor(scrollThumbWidth / 2) - this.scrollbarElement.left;
			const slideToScrollTo = Math.ceil(this.centerPointOfScrollThumb / scrollThumbWidth);
			this.scrollThumbTransition = (slideToScrollTo - 1) * scrollThumbWidth;
			this.transitionScrollThumb(slideToScrollTo, scrollThumbWidth);
			this.transitionScrollContent(slideToScrollTo);
		}
	}

	realTimeTransition(transition) {
		if (this.mouseDownOnSlider) {
			this.scrollContentElement.style.transform = `translateX(${transition}px)`;
		} else {
			this.scrollContentElement.style.transform = `translateX(-${transition}px)`;
		}
		this.scrollThumbElement.style.transform = `translateX(${this.scrollThumbTransition}px)`;
	}

	translationOnMouseMove(e) {
		const { scrollThumbWidth } = this.state;
		const scrollThumbTransition = e.clientX - this.clickedPointXPosition;
		const maxTransition = this.scrollbarWidth - scrollThumbWidth;
		this.scrollThumbTransition = this.startScrollThumbTransition + scrollThumbTransition;
		this.scrollThumbTransition =
			// eslint-disable-next-line no-nested-ternary
			this.scrollThumbTransition < 0 ? 0 : this.scrollThumbTransition > maxTransition ? maxTransition : this.scrollThumbTransition;
		const percentageScrollThumbShift = (this.scrollThumbTransition / this.scrollbarWidth).toFixed(2);
		this.newScrollContentTransition = Math.round(this.scrollContentWidth * percentageScrollThumbShift);
		this.realTimeTransition(this.newScrollContentTransition);
	}

	translationOnTouchMove(e) {
		e.preventDefault();
		const { scrollThumbWidth } = this.state;
		const scrollThumbTransition = e.touches[0].screenX - this.clickedPointXPosition;
		const maxTransition = this.scrollbarWidth - scrollThumbWidth;
		this.scrollThumbTransition = this.startScrollThumbTransition + scrollThumbTransition;
		this.scrollThumbTransition =
			// eslint-disable-next-line no-nested-ternary
			this.scrollThumbTransition < 0 ? 0 : this.scrollThumbTransition > maxTransition ? maxTransition : this.scrollThumbTransition;
		const percentageScrollThumbShift = (this.scrollThumbTransition / this.scrollbarWidth).toFixed(2);
		this.newScrollContentTransition = Math.round(this.scrollContentWidth * percentageScrollThumbShift);
		this.realTimeTransition(this.newScrollContentTransition);
	}

	translationOnMouseMoveOnSlider(e) {
		this.setState({ wasContentScrolled: true });
		this.newScrollContentTransition = e.clientX - this.clickedPointXPosition - this.scrollContentTransition;
		const maxTransition = this.scrollContentWidth - this.slideWidth;
		this.handleTransition(maxTransition);
	}

	translationOnTouchMoveOnSlider(e) {
		this.setState({ wasContentScrolled: true });
		this.newScrollContentTransition = e.touches[0].screenX - this.clickedPointXPosition - this.scrollContentTransition;
		const maxTransition = this.scrollContentWidth - this.slideWidth;
		this.handleTransition(maxTransition);
	}

	render() {
		const { scrollThumbWidth, activeSlideNumber, wasContentScrolled } = this.state;
		const { data: sliderData, activeSite } = this.props;
		this.slidesNumber = sliderData.length;
		return (
			<StyledSliderWithScrollWrapper id={GALLERY_CONTENT}>
				<StyledCurrentSliderElementBorder id="green-circles-product-slider">
					<ConcentricCircles className="slajder" />
				</StyledCurrentSliderElementBorder>
				<StyledClientsSliderWrapper
					id={SCROLL_CONTENT}
					data-cursor="green-circle-slider"
					className="custom-cursor-animation custom-slider"
					onMouseMove={this.handleMouseMoveOnSlider}
					// onMouseOut={this.handleMouseOutOnSlider}
					onMouseDown={this.handleMouseDownOnSlider}
					onMouseUp={this.handleMouseUpOnSlider}
					onTouchStart={this.handleTouchStartOnSlider}
				>
					{sliderData.map((item, key) => {
						const categoryName = item?.wizytowkaNazwa || '';
						const categoryDescription = item?.wizytowkaOpis || '';
						const categoryImage = item?.wizytowkaZdjecie?.[0]?.url || '';
						const categoryPageUri = item?.uri || '';
						const isOnlyOneProductInCategory = item?.remoteChildren?.length === 1 || false;
						const onlyProductUri = item?.remoteChildren?.[0]?.uri || '';
						return (
							<SingleCategoryCard
								uri={categoryPageUri}
								activeSite={activeSite}
								key={categoryName}
								activeSlide={activeSlideNumber}
								orderNumber={key + 1}
								categoryName={categoryName}
								categoryDescription={categoryDescription}
								categoryImage={categoryImage}
								wasContentScrolled={wasContentScrolled}
								isOnlyOneProductInCategory={isOnlyOneProductInCategory}
								onlyProductUri={onlyProductUri}
							/>
						);
					})}
				</StyledClientsSliderWrapper>
				<StyledScrollbarPromptWrapper />
				<StyledCustomScrollbar
					id="scrollbar"
					onClick={this.handleScrollClick}
					onMouseDown={this.handleMouseDown}
					onTouchStart={this.handleTouchStart}
				>
					<StyledScrollbarThumb width={`${scrollThumbWidth}px`} id={SCROLLBAR_THUMB} />
				</StyledCustomScrollbar>
			</StyledSliderWithScrollWrapper>
		);
	}
}

export default CustomSlider;
