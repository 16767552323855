/*eslint-disable*/

import React from 'react';
// eslint-disable-next-line no-unused-vars
import styled from 'styled-components';

import { connect, useDispatch } from 'react-redux';
import PageContainer from '../common/PageContainer';

const StyledModuleWrapper = styled.div`
	display: flex;
	height: 100%;
	justify-content: center;
	width: 100%;
	background-color: ${({ theme }) => theme.colors.white};
`;

const StyledIllutration = styled.div`
	background-repeat: no-repeat;
	background-size: cover;
	height: 100vh;
	width: 100vw;

	@media (orientation: landscape) {
		background-image: url('${({ desktop }) => desktop}');
		background-position: bottom;
	}

	@media (orientation: portrait) {
		background-image: url('${({ mobile }) => mobile}');
		background-position: top;
	}
`;

const StyledIllutrationImage = styled.img`
	height: 100vh;
	width: 100vw;
	@media (max-width: 768px) {
		background-position: top;
	}
`;

const ErrorIlustrations = ({ data }) => {
	const moduleId = data?.remoteId + data?.__typename || '';

	const dispatch = useDispatch();
	const moduleData = data?.errorPageIlustracje || [];

	let desktop = '';
	let mobile = '';
	let toggle = '';

	moduleData.forEach((item) => {
		if (item.__typename === 'Craft_errorPageIlustracje_ilustracje_BlockType') {
			desktop = item?.ilustracjaDesktop?.[0]?.url || '';
			mobile = item?.ilustracjaMobile?.[0]?.url || '';
		}
		if (item.__typename === 'Craft_errorPageIlustracje_kolorLoga_BlockType') {
			toggle = item?.kolorLoga || '';
		}
	});

	return (
		<PageContainer>
			<StyledModuleWrapper id={moduleId}>
				<StyledIllutration desktop={desktop} mobile={mobile}>
					{/*<StyledIllutrationImage src={desktop} />*/}
				</StyledIllutration>
			</StyledModuleWrapper>
		</PageContainer>
	);
};

export default ErrorIlustrations;
