/*eslint-disable*/

import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import Fade from 'react-reveal/Fade';
import ButtonCircle from '../common/ButtonCircle';
import PageContainer from '../common/PageContainer';
import TextBanner from '../typography/TextBanner';
import { Link } from 'gatsby';
import getSlug from '../../utils/getSlug';
import TextHeader2 from '../typography/TextHeader2';

const StyledBackgroundColor = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	height: 100%;
	position: relative;
	width: 100%;
`;

const StyledBackgroundWrapper = styled.div`
	align-items: center;
	background-image: url(${({ bgImg }) => bgImg});
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	display: flex;
	height: 115vh;
	justify-content: center;
	width: 100vw;
	@media (min-width: 400px) {
		height: 100vh;
	}
	@media (min-width: 1240px) {
		background-attachment: fixed;
	}
`;

const StyledModuleWrapper = styled.div`
	padding: 50px 0;
	width: 100%;
	@media ${({ theme }) => theme.device.w_1440} {
		flex-direction: row;
		align-items: ${({ buttonPosition }) => buttonPosition};
		display: flex;
		justify-content: space-between;
	}
`;

const StyledTextWrapper = styled.div`
	padding: 40px 0;
	width: 100%;
	@media ${({ theme }) => theme.device.w_425} {
		padding: 110px 0px 40%;
	}
	@media (min-width: 768px) {
		//padding-right: 25%;
	}
	@media ${({ theme }) => theme.device.w_1024} {
		width: 100%;
		padding: 90px 0;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		width: 100%;
		padding: 220px 0;
	}
	@media ${({ theme }) => theme.device.w_1920} {
		width: 75%;
	}
	@media ${({ theme }) => theme.device.w_2560} {
		width: 55%;
	}
`;

const StyledButtonWrapper = styled.div`
	position: relative;
	text-align: right;
	@media ${({ theme }) => theme.device.w_1440} {
		text-align: center;
		margin-bottom: 24px;
	}
`;

const HighlightedNews = ({ data, activeSite }) => {
	const moduleData = data?.modulWyroznionaAktualnosc[0]?.wybierzAktualnosc?.[0] || [];
	const [customCursor, setCustomCursor] = useState(' ');

	const headingBig = moduleData?.aktualnoscNaglowekDuzy || '';
	const headingSmall = moduleData?.aktualnoscNaglowekMaly || '';
	const slug = moduleData?.slug || '';
	const uri = moduleData?.uri || '';
	const img = moduleData?.aktualnoscZdjecie[0].url || '';

	useEffect(() => {
		const getCustomCursor = document.getElementById('custom-cursor-news');
		setCustomCursor(getCustomCursor);
	});

	const handleMouseMoveOnSlider = (e) => {
		const offsetX = e.clientX - 34;
		const offsetY = e.clientY - 34;
		customCursor.style.top = `${offsetY}px`;
		customCursor.style.left = `${offsetX}px`;
	};

	return (
		<PageContainer className="full-width">
			<StyledBackgroundColor
				data-cursor={'green-circle'}
				as={Link}
				to={`/${getSlug(uri, activeSite)}`}
				onMouseMove={(e) => handleMouseMoveOnSlider(e)}
			>
				<StyledBackgroundWrapper bgImg={img}>
					<StyledModuleWrapper className="inner-wrapper-small padding-right">
						<>
							<StyledTextWrapper>
								<TextHeader2 className="white mb-50">{headingSmall}</TextHeader2>
								<TextBanner className="photoDescription grey-light thin">{headingBig}</TextBanner>
							</StyledTextWrapper>
						</>
					</StyledModuleWrapper>
				</StyledBackgroundWrapper>
			</StyledBackgroundColor>
		</PageContainer>
	);
};

export default HighlightedNews;
