import React from 'react';
import styled from 'styled-components';

const StyledInputWrapper = styled.div`
	position: relative;
	&:before {
		content: '';
		position: absolute;
		bottom: 4px;
		right: 20px;
		width: 12px;
		height: 13px;
		background-color: ${({ theme }) => theme.colors.stringBlue};
		z-index: 10;
		transition: margin 0.5s linear;
	}

	&:hover {
		&:before {
			margin-right: calc(100% - 50px);
		}
	}
	&.inputCareer,
	&.newMessageClass,
	&.inputCareerMessage {
		&:before {
			background-color: rgba(0, 0, 0, 1);
			height: 5px;
			bottom: 4px;
		}

		&:hover {
			&:before {
				margin-right: calc(100% - 50px);
			}
		}
	}

	&:focus-within,
	&:hover {
		&:before {
			margin-right: calc(100% - 50px);
		}
	}
`;

const StyledInputWrapperTextarea = styled(StyledInputWrapper)`
	&:focus-within,
	&:hover {
		&:before {
			margin-right: calc(100% - 50px);
		}
	}
`;

const StyledInput = styled.input`
	background-color: transparent;
	border: 0;
	border-bottom: 1px solid grey;
	caret-color: ${({ theme }) => theme.colors.green};
	color: ${({ theme }) => theme.colors.greyDark};
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 20px;
	font-weight: ${({ theme }) => theme.fontWeight.thin};
	line-height: 40px;
	position: relative;
	top: -4px;
	width: 100%;
	z-index: 1;
	&.margin {
		margin-bottom: 50px;
	}
	&::placeholder {
		bottom: 0;
		color: ${({ theme }) => theme.colors.greyDark};
		font-size: 20px;
		font-weight: ${({ theme }) => theme.fontWeight.thin};
		line-height: 40px;
	}
	&:last-of-type {
		margin-bottom: 30px;
	}
	&:active {
		border: none;
		border-bottom: 1px solid grey;
	}
	&:focus {
		border: none;
		border-bottom: 1px solid grey;
	}
`;

const StyledLabel = styled.label`
	//color: black;
	bottom: -20px;
	position: relative;
	width: 100%;

	textarea {
		min-height: 120px;
		resize: none;
		@media (min-width: 768px) {
			min-height: 200px;
		}
	}
`;
const StyledErrorMessage = styled.div`
	color: red;
	font-size: 11px;
	font-weight: 300;
	margin-bottom: -5px;
	margin-top: -12px;
`;

const FormInput = (props) => {
	const { isTextarea, errorMessage } = props;
	return (
		<StyledLabel {...props}>
			{/* eslint-disable-next-line react/destructuring-assignment */}
			{/* {props.label} */}
			{isTextarea && (
				<StyledInputWrapperTextarea {...props}>
					<StyledInput as="textarea" {...props} />
				</StyledInputWrapperTextarea>
			)}
			{!isTextarea && (
				<StyledInputWrapper {...props}>
					{' '}
					<StyledInput {...props} />
				</StyledInputWrapper>
			)}
			{errorMessage && <StyledErrorMessage>{errorMessage}</StyledErrorMessage>}
		</StyledLabel>
	);
};

export default FormInput;
