import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import PageContainer from '../common/PageContainer';

const StyledVideoContainer = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	margin: 0;
	padding-top: 56.25%;
	position: relative;
	width: 100%;
	> div {
		left: 0;
		position: absolute;
		top: 0;
		z-index: 1;
	}
`;

const StyledInvisibleBlend = styled.div`
	background-color: transparent;
	height: 100%;
	left: 0;
	position: absolute;
	top: 0;
	width: 100%;
	z-index: 5;
`;

const VideoModule = ({ data }) => {
	const [customCursor, setCustomCursor] = useState('');
	const [isVideoPlaying, setVideoPlaying] = useState(false);
	useEffect(() => {
		const getCustomCursor = document.getElementById('custom-cursor-video');
		setCustomCursor(getCustomCursor);
	});

	const toggleVideoPlay = () => {
		setVideoPlaying(!isVideoPlaying);
	};

	const handleMouseMoveOnSlider = (e) => {
		const offsetX = e.clientX - 34;
		const offsetY = e.clientY - 34;
		customCursor.style.top = `${offsetY}px`;
		customCursor.style.left = `${offsetX}px`;
	};

	const moduleData = data?.modulVideo?.[0] || [];
	const videoFileUrl = moduleData?.videoPlik?.[0]?.url || '';
	const videoUrl = moduleData?.videoUrl || '';
	const urlSource = videoUrl || videoFileUrl;

	return (
		<PageContainer>
			<StyledVideoContainer
				data-video-playing={isVideoPlaying ? 'playing' : ''}
				id="video-module"
				data-cursor="green-circle-video-module"
				onMouseMove={(e) => handleMouseMoveOnSlider(e)}
			>
				<ReactPlayer width="100%" height="100%" controls={false} url={urlSource} playing={isVideoPlaying} />
				<StyledInvisibleBlend onClick={toggleVideoPlay} />
			</StyledVideoContainer>
		</PageContainer>
	);
};

export default VideoModule;
