import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';

const Text = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 14px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 28px;
	&.change-color {
		color: var(--text-color);
		transition: color 0.4s ease-in-out;
	}

	@media ${({ theme }) => theme.device.w_768} {
		font-size: 12px;
		line-height: 24px;
	}

	@media ${({ theme }) => theme.device.w_1024} {
		font-size: 18px;
		line-height: 38px;
	}
`;

const TextNews = ({ className, children }) => {
	return <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextNews;
