/*eslint-disable*/

import React from 'react';
import styled from 'styled-components';

import PageContainer from '../common/PageContainer';

import TextSubpageHeadline from '../typography/TextSubpageHeadline';
import TextSubpageHeadlineSmall from '../typography/TextSubpageHeadlineSmall';

const StyledBackgroundWrapper = styled.div`
	//background-color: ${({ theme }) => theme.colors.white};
	background-color: va(--background-color);
	height: 60vh;
	width: 100%;
	padding-top: 150px;
	@media (min-width: 500px) {
		height: 50vh;
	}
	@media ${({ theme }) => theme.device.w_768} {
		padding: 20% 0 0;
	}

	@media ${({ theme }) => theme.device.w_1024} {
		height: 40vh;
		@media (orientation: landscape) {
			height: 70vh;
		}
	}
	@media (min-width: 1200px) {
		padding: 16% 0 5%;
		height: 60vh;
	}

	@media ${({ theme }) => theme.device.w_1440} {
		padding: 0;
	}
	//padding: 140px 0;
`;

const StyledModuleWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
`;

const StyledHeadlineWrapper = styled.div`
	width: 100%;
	height: auto;

	@media ${({ theme }) => theme.device.w_425} {
		width: 80%;
	}
	@media ${({ theme }) => theme.device.w_768} {
		width: 90%;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		width: 60%;

		padding-top: 23vh;
	}
	@media (min-width: 1800px) {
		width: 60%;
		padding-top: 23vh;
	}
	@media (min-width: 2550px) {
		width: 50%;
		padding-top: 26vh;
	}
`;

const HeadlineTwoLines = ({ data }) => {
	const moduleData = data?.modulNaglowekPodstrona2Linijki?.[0] || [];
	const moduleId = data?.remoteId + data?.__typename || '';

	const bigHeading = moduleData?.naglowekDuzy || '';
	const smallHeading = moduleData?.naglowekMaly || '';

	return (
		<PageContainer>
			<StyledBackgroundWrapper id={moduleId}>
				<StyledModuleWrapper className="inner-wrapper-large padding-right">
					<StyledHeadlineWrapper>
						<TextSubpageHeadlineSmall className="black">{smallHeading}</TextSubpageHeadlineSmall>
						<TextSubpageHeadline className="black product">{bigHeading}</TextSubpageHeadline>
					</StyledHeadlineWrapper>
				</StyledModuleWrapper>
			</StyledBackgroundWrapper>
		</PageContainer>
	);
};

export default HeadlineTwoLines;
