import dayjs from 'dayjs';

require('dayjs/locale/pl');
require('dayjs/locale/en');

const getDateInProperFormat = (activeSite, createdDate) => {
	if (activeSite === 'pl') {
		return dayjs(createdDate).locale('pl').format('D_MMM');
	}
	return dayjs(createdDate).locale('en').format('D_MMM');
};

export default getDateInProperFormat;
