import React from 'react';
import styled from 'styled-components';

const LoaderBlend = styled.div`
	align-items: center;
	display: flex;
	height: 100vh;
	justify-content: center;
	left: 0;
	overflow: hidden;
	position: fixed;
	top: 0;
	width: 100vw;
	z-index: 100000;

	&:before {
		background-color: black;
		content: '';
		height: 100%;
		left: 0;
		opacity: 0.95;
		position: absolute;
		top: 0;
		width: 100%;
	}
`;

const CustomLoader = styled.div`
	display: flex;
	height: 100px;
	justify-content: space-between;
	left: 50%;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	width: 100px;
`;

const Square = styled.div`
	animation: square-animation 0.5s alternate infinite ease-in;
	background-color: white;
	height: 25px;
	transform: scale(0.5);
	width: 25px;

	&:nth-child(2) {
		animation-delay: 0.5s;
	}

	@keyframes square-animation {
		0% {
			transform: scale(0.5);
		}
		100% {
			transform: scale(1);
		}
	}
`;

const CustomSpinner = () => {
	return (
		<>
			<LoaderBlend>
				<CustomLoader>
					<Square />
					<Square />
					<Square />
				</CustomLoader>
			</LoaderBlend>
		</>
	);
};

export default CustomSpinner;
