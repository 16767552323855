/*eslint-disable*/

import React from 'react';
import styled from 'styled-components';
import PageContainer from '../common/PageContainer';
import ButtonRectangle from '../common/ButtonRectangle';
import TextSubpageDescription from '../typography/TextSubpageDescription';
import TextSubpageHeadline from '../typography/TextSubpageHeadline';
import TextSubpageHeadlineSmall from '../typography/TextSubpageHeadlineSmall';
import { sendData } from '../../utils/sendEmailMethod';
import FormInput from './FormInput';
import CustomSpinner from './CustomSpinner';
import FormModal from './FormModal';
import manageScroll from '../../utils/manageScroll';
import CheckboxInfo from './CheckboxInfo';

const StyledBackgroundWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.white};
	height: 100%;
	width: 100%;
	padding-top: 150px;
	@media ${({ theme }) => theme.device.w_768} {
		padding: 20% 0 0;
	}
	@media (min-width: 1200px) {
		padding: 16% 0 5%;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		//padding: 12% 0;
		padding: 23vh 0 0;
	}
	@media ${({ theme }) => theme.device.w_1920} {
		padding-top: 23vh;
	}
	@media (min-width: 2550px) {
		padding-top: 26vh;
	}
`;

const StyledModuleWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	@media ${({ theme }) => theme.device.w_1024} {
		flex-direction: row;
	}
	// margin-top: 10%;
`;

const StyledHeadlineWrapper = styled.div`
	width: 100%;
	height: auto;
	@media ${({ theme }) => theme.device.w_768} {
	}

	@media ${({ theme }) => theme.device.w_1024} {
		width: 50%;
	}

	@media ${({ theme }) => theme.device.w_1440} {
		width: 45%;
	}
`;

const StyledFormWrapper = styled.form`
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin-top: 40px;
	@media ${({ theme }) => theme.device.w_768} {
	}

	@media ${({ theme }) => theme.device.w_1024} {
		margin-top: 0px;
		width: 45%;
	}

	@media ${({ theme }) => theme.device.w_1440} {
		width: 45%;
	}

	@media ${({ theme }) => theme.device.w_1920} {
		width: 35%;
	}
`;

const StyledForm = styled.form`
	width: 100%;
	position: relative;
	margin-top: 70px;
	z-index: 1;
	@media ${({ theme }) => theme.device.w_1024} {
		margin-top: 0;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		top: 110px;
	}
`;

const StyledInput = styled.input`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	padding-top: 20px;
	width: 100%;
	border: 0;
	background-color: transparent;
	border-bottom: 1px solid grey;
	margin-bottom: 10px;
	position: relative;
	z-index: 10;
	&.margin {
		margin-bottom: 50px;
	}
	&:last-of-type {
		margin-bottom: 30px;
	}
	&:active {
		border: none;
	}
	&:focus {
		border: none;
	}
`;

const StyledLabel = styled.label`
	width: 100%;
	//color: black;
	position: relative;
	bottom: -20px;
`;

const Popup = styled.div`
	position: absolute;
	width: 30vw;
	height: 20vh;
	background-color: green;
`;

const FormSendInfo = styled.p`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 16px;
	font-weight: ${({ theme }) => theme.fontWeight.regular};
	line-height: 43px;
`;

const CareerPrivacyPolicyWrapper = styled.div`
	width: 100%;
	display: flex;
	align-items: center;
`;

class ContactForm extends React.Component {
	state = {
		company: '',
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		message: '',
		isEmailPending: false,
		privacyPolicyAgree: false,
		isPopupVisible: false,
		companyInvalid: false,
		firstNameInvalid: false,
		lastNameInvalid: false,
		phoneInvalid: false,
		emailInvalid: false,
		messageInvalid: false,
		firstNameInvalidMsg: '',
		companyInvalidMsg: '',
		lastNameInvalidMsg: '',
		emailInvalidMsg: '',
		phoneInvalidMsg: '',
		messageInvalidMsg: '',
		isFormSent: false,
	};

	initialState = {
		company: '',
		firstName: '',
		lastName: '',
		phone: '',
		email: '',
		message: '',
		privacyPolicyAgree: false,
	};

	handleInputChange = (event) => {
		const target = event.target;
		const value = target.type === 'checkbox' ? target.checked : target.value;
		const name = target.name;
		this.setState({ [name]: value, [name + 'Invalid']: false, [name + 'InvalidMsg']: '' });
	};

	// handleDateChange = (date) => {
	// 	this.setState({
	// 		preferredDate: date,
	// 	});
	// };
	//
	// setStartDate = (date) => {
	// 	this.setState({
	// 		preferredTime: date,
	// 	});
	// };

	togglePopup() {
		this.setState({ isPopupVisible: !this.state.isPopupVisible });
	}

	handleSubmit = (event) => {
		event.preventDefault();
		try {
			this.sendEmail();
		} catch (err) {
			console.log(err);
		}
	};

	async sendEmail() {
		this.setState({ isEmailPending: true });
		const data = new FormData();

		data.append('fromEmail', this.state.email);
		data.append('fromName', this.state.firstName);
		data.append('action', 'contact-form/send');
		data.append('message[body]', this.state.message);
		data.append('message[phone]', this.state.phone);
		data.append('message[company]', this.state.company);

		const rawResponse = await sendData(data);
		const responseData = await rawResponse.json();

		const getInvalidInput = {
			fromEmail: 'emailInvalid',
			fromName: 'firstNameInvalid',
			'message[body]': 'messageInvalid',
			'message[phone]': 'phoneNumberInvalid',
		};

		if (responseData.success) {
			this.togglePopup();
			this.setState({ isFormSent: true });
			this.setState(this.initialState);
			this.setState({ isEmailPending: false });
		} else if (responseData.errors) {
			Object.keys(responseData.errors).forEach((key) => {
				this.setState({ isEmailPending: false });
				this.setState({ [getInvalidInput[key]]: true });
				const errorMessagesArray = responseData.errors[key];
				errorMessagesArray.forEach((errorMsg) => {
					this.setState({ [getInvalidInput[key] + 'Msg']: errorMsg });
				});
			});
		}
	}

	render() {
		const { data } = this.props;
		const moduleData = data?.modulNaglowekFormularz || [];

		let headerBig = '';
		let headerSmall = '';
		let description = '';

		let email = '';
		let name = '';
		let company = '';
		let phone = '';
		let buttonText = '';
		let content = '';
		let checkboxText = '';
		let infoSend = '';

		// eslint-disable-next-line array-callback-return
		moduleData.map((item) => {
			if (item.__typename === 'Craft_modulNaglowekFormularz_naglowek_BlockType') {
				headerBig = item?.naglowekDuzy || '';
				headerSmall = item?.naglowekMaly || '';
				description = item?.opis || '';
			}
			if (item.__typename === 'Craft_modulNaglowekFormularz_formularz_BlockType') {
				email = item?.email || '';
				name = item?.imieINazwisko || '';
				company = item?.nazwaFirmy || '';
				phone = item?.nrKontaktowy || '';
				buttonText = item?.tekstPrzycisk || '';
				content = item?.trescWiadomosci || '';
				checkboxText = item?.tekstCheckbox || '';
				infoSend = item?.potwierdzenieWyslania || '';
			}
		});
		return (
			<PageContainer>
				<FormModal isModal={this.state.isFormSent} infoSend={infoSend} />
				<StyledBackgroundWrapper>
					<StyledModuleWrapper className="inner-wrapper-large padding-right">
						<StyledHeadlineWrapper>
							<TextSubpageHeadlineSmall className="black">{headerSmall}</TextSubpageHeadlineSmall>
							<TextSubpageHeadline className="black product">{headerBig}</TextSubpageHeadline>
							<TextSubpageDescription className="black">{description}</TextSubpageDescription>
						</StyledHeadlineWrapper>

						<StyledFormWrapper onSubmit={this.handleSubmit.bind(this)}>
							<FormInput
								label={company}
								placeholder={company}
								type="text"
								name="company"
								className={`${this.state.companyInvalid && 'invalid'}`}
								errorMessage={this.state.companyInvalidMsg}
								required
								value={this.state.company}
								onChange={this.handleInputChange}
							/>

							<FormInput
								label={name}
								placeholder={name}
								type="text"
								name="firstName"
								className={`${this.state.firstNameInvalid && 'invalid'}`}
								errorMessage={this.state.firstNameInvalidMsg}
								required
								value={this.state.firstName}
								onChange={this.handleInputChange}
							/>

							<FormInput
								label={email}
								placeholder={email}
								type="email"
								name="email"
								className={`${this.state.emailInvalid && 'invalid'}`}
								errorMessage={this.state.emailInvalidMsg}
								required
								value={this.state.email}
								onChange={this.handleInputChange}
							/>

							<FormInput
								label={phone}
								placeholder={phone}
								type="phone"
								name="phone"
								className={`${this.state.phoneInvalid && 'invalid'}`}
								errorMessage={this.state.phoneInvalidMsg}
								required
								value={this.state.phone}
								onChange={this.handleInputChange}
							/>

							<FormInput
								label={content}
								placeholder={content}
								type="text"
								name="message"
								className={`${this.state.messageInvalid && 'invalid'}`}
								errorMessage={this.state.messageInvalidMsg}
								isTextarea="true"
								required
								value={this.state.message}
								onChange={this.handleInputChange}
							/>
							<CareerPrivacyPolicyWrapper>
								<CheckboxInfo
									name="privacyPolicyAgree"
									type="checkbox"
									required
									value={checkboxText}
									checked={this.state.privacyPolicyAgree}
									onChange={this.handleInputChange}
								>
									<FormSendInfo>{checkboxText}</FormSendInfo>
								</CheckboxInfo>
							</CareerPrivacyPolicyWrapper>
							<ButtonRectangle type="submit" className="black-text">
								{buttonText}
							</ButtonRectangle>
						</StyledFormWrapper>

						{/*<Popup*/}
						{/*	// modalConfirmData={modalConfirmData}*/}
						{/*	isVisible={this.state.isPopupVisible}*/}
						{/*	hide={this.togglePopup.bind(this)}*/}
						{/*/>*/}

						{/*{this.state.isEmailPending && <CustomSpinner />}*/}
						{/*<CustomSpinner />*/}
					</StyledModuleWrapper>
				</StyledBackgroundWrapper>
			</PageContainer>
		);
	}
}

export default ContactForm;
