import React from 'react';
import ReactVivus from 'react-vivus';

const VivusWrapper = ({ id, fileName }) => (
	<ReactVivus
		id={id}
		option={{
			file: fileName,
			animTimingFunction: 'EASE-OUT',
			type: 'sync',
			duration: 50,
			forceRender: true,
			reverseStack: true,
		}}
	/>
);

export default VivusWrapper;
