/*eslint-disable*/

import React, { CSSProperties } from 'react';
import styled from 'styled-components';

import PageContainer from '../common/PageContainer';
import ButtonRectangle from '../common/ButtonRectangle';
import TextBanner from '../typography/TextBanner';
import TextSubpageDescription from '../typography/TextSubpageDescription';
import TextSubpageHeadline from '../typography/TextSubpageHeadline';
import TextSubpageHeadlineSmall from '../typography/TextSubpageHeadlineSmall';

import { connect } from 'react-redux';

const StyledBackgroundWrapper = styled.div`
	//background-color: ${({ theme }) => theme.colors.white};
	height: 100%;
	width: 100%;
	padding: 120px 0 80px;
	@media (min-width: 550px) {
		padding: 140px 0;
	}
`;

const StyledModuleWrapper = styled.div`
	width: 100%;
	height: 100%;
	display: grid;
	justify-content: space-between;
	grid-template-columns: 1fr;
	grid-template-rows: 1fr 1fr 1fr;
	@media (min-width: 550px) {
		grid-template-columns: 1fr 1fr;
		grid-template-rows: 1fr 1fr;
	}
	@media ${({ theme }) => theme.device.w_1024} {
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 1fr;
	}
`;

const StyledTitle = styled.p`
	font-size: 14px;
	line-height: 30px;
	font-weight: ${({ theme }) => theme.fontWeight.thin};
	@media (min-width: 1200px) {
		font-size: 18px;
		line-height: 34px;
	}
	@media ${({ theme }) => theme.device.w_1920} {
		font-size: 20px;
		line-height: 38px;
	}
	color: var(--text-color);
	&.bold {
		font-weight: ${({ theme }) => theme.fontWeight.bold};
		margin-bottom: 16px;
	}
`;

const StyledBlock = styled.div`
	width: 100%;
	height: 100%;

	&:nth-of-type(2) {
		margin-top: 30px;
	}

	&:nth-of-type(3) {
		margin-top: 30px;
	}

	@media (min-width: 550px) {
		&:nth-of-type(2) {
			margin-top: 0px;
		}
		&:nth-of-type(3) {
			margin-top: 30px;
		}
	}
	@media ${({ theme }) => theme.device.w_1024} {
		&:nth-of-type(3) {
			margin-top: 0px;
		}
	}
`;

const ContactData = ({ data }) => {
	const moduleData = data?.modulDaneKontaktowe || [];
	const moduleId = data?.remoteId + data?.__typename || '';

	let contactDataArray = [];

	moduleData.forEach((item) => {
		if (item.__typename === 'Craft_modulDaneKontaktowe_daneKontaktowe_BlockType') {
			contactDataArray.push({
				descriptionOne: item?.opis1 || '',
				descriptionTwo: item?.opis2 || '',
				descriptionThree: item?.opis3 || '',
				title: item?.tytul || '',
			});
		}
		if (item.__typename === '"Craft_modulDaneKontaktowe_background_BlockType"') {
		}
	});

	return (
		<PageContainer>
			<StyledBackgroundWrapper id={moduleId}>
				<StyledModuleWrapper className="inner-wrapper-large padding-right">
					{contactDataArray.map((item) => {
						const title = item?.title || '';
						const description1 = item?.descriptionOne || '';
						const description2 = item?.descriptionTwo || '';
						const description3 = item?.descriptionThree || '';
						return (
							<StyledBlock>
								<StyledTitle className="bold">{title}</StyledTitle>
								<StyledTitle>{description1}</StyledTitle>
								<StyledTitle>{description2}</StyledTitle>
								<StyledTitle>{description3}</StyledTitle>
							</StyledBlock>
						);
					})}
				</StyledModuleWrapper>
			</StyledBackgroundWrapper>
		</PageContainer>
	);
};

export default ContactData;
