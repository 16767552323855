export async function sendData(data) {
	const url = 'https://craftcms.kangurelectronics.pl/';

	const rawData = fetch(url, {
		method: 'POST',
		headers: {
			Accept: 'application/json',
		},
		// mode: 'cors',
		body: data,
	});

	return rawData;
}
