import React from 'react';
import DescriptionButton from './descriptionButton/DescriptionButton';
import PhotoDescriptionButton from './photoDescriptionButton/PhotoDescriptionButton';
import HeadingButton from './headingButton/HeadingButton';
import ProductSlider from './customSlider/ProductSlider';
import Workflow from './worklfow/Workflow';
import News from './news/News';
import HeadlineForm from './headlineForm/HeadlineForm';
import ContactData from './contactData/ContactData';
import ErrorIlustrations from './errorIlustrations/ErrorIlustrations';
import HeadlineTwoLines from './headlineTwoLines/HeadlineTwoLines';
import HeadlinePhoto from './headlinePhoto/HeadlinePhoto';
import AllNews from './allNews/AllNews';
import HighlightedNews from './highlightedNews/HighlightedNews';
import ProductsVerticalSlider from './productsVerticalSlider/ProductsVerticalSlider';
import HeadlineBackground from './headlineBackground/HeadlineBackground';
import ProductTable from './productTable/ProductTable';
import HeaderDescription from './headerDescription/HeaderDescription';
import VideoModule from './videoModule/VideoModule';
import GalleryModule from './galleryModule/GalleryModule';

import CategoriesProducts from './categoriesProducts/CategoriesProducts';

const ExtendedContent = (props) => {
	const { data, activeSite, allNewsData, chooseCategory, productData, productSlug, allProductPageSlug, productsPerCategoryData } =
		props || [];

	if (Array.isArray(data)) {
		return data.map((block, i) => {
			switch (block.__typename) {
				case 'Craft_moduly_modulOpis_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <DescriptionButton key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulZdjecieZOpisem_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <PhotoDescriptionButton key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulNapiszDoNas_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <HeadingButton key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulSliderWizytowkiKategoriiProduktow_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <ProductSlider key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulProcesPracy_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <Workflow key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulWizytowkiAktualnosci_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <News key={i} data={block} activeSite={activeSite} />;

				case 'Craft_moduly_modulNaglowekFormularz_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <HeadlineForm key={i} data={block} activeSite={activeSite} />;

				case 'Craft_moduly_modulDaneKontaktowe_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <ContactData key={i} data={block} activeSite={activeSite} />;

				case 'Craft_moduly_errorPageIlustracje_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <ErrorIlustrations key={i} data={block} activeSite={activeSite} />;

				case 'Craft_moduly_modulNaglowekPodstrona2Linijki_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <HeadlineTwoLines key={i} data={block} activeSite={activeSite} />;

				case 'Craft_moduly_modulWizytowkiNaPodstronieAktualnosci_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <AllNews allNewsData={allNewsData} key={i} data={block} activeSite={activeSite} />;

				case 'Craft_moduly_modulWyroznionaAktualnosc_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <HighlightedNews key={i} data={block} activeSite={activeSite} />;

				case 'Craft_moduly_modulSliderNaPodstronieProdukty_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <ProductsVerticalSlider key={i} data={block} activeSite={activeSite} />;

				case 'Craft_moduly_modulNaglowekZdjecie_Entry':
					return (
						<HeadlinePhoto
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							data={block}
							activeSite={activeSite}
							chooseCategory={chooseCategory}
							allProductPageSlug={allProductPageSlug}
							productData={productData}
							productSlug={productSlug}
						/>
					);
				case 'Craft_moduly_modulProduktTabelka_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <ProductTable key={i} data={block} activeSite={activeSite} />;

				case 'Craft_moduly_modulNaglowekTlo_Entry':
					return (
						<HeadlineBackground
							// eslint-disable-next-line react/no-array-index-key
							key={i}
							data={block}
							activeSite={activeSite}
							productData={productData}
							allProductPageSlug={allProductPageSlug}
						/>
					);

				case 'Craft_moduly_modulNaglowekOpis_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <HeaderDescription key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulVideo_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <VideoModule key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulGaleria_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <GalleryModule key={i} data={block} activeSite={activeSite} />;
				case 'Craft_moduly_modulWszystkieProduktyZKategorii_Entry':
					// eslint-disable-next-line react/no-array-index-key
					return <CategoriesProducts key={i} productData={productsPerCategoryData} activeSite={activeSite} />;
				default:
					// return <p>Brak obsługi dla {block.__typename}</p>;
					return null;
			}
		});
	}

	return null;
};

export default ExtendedContent;
