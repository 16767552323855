import React, { useState } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import PageContainer from '../common/PageContainer';
import CustomSlider from './CustomSlider';
import TextHeader from '../typography/TextHeader';

const StyledSliderWrapper = styled.div`
	//overflow-x: hidden;
	//overflow-y: visible;
	background-color: ${({ theme }) => theme.colors.black};
	padding-bottom: 120px;
	@media ${({ theme }) => theme.device.w_768} {
		padding-bottom: 200px;
	}
	@media (min-width: 1024px) {
		margin: 0;
	}
`;

const StyledBackgroundWrapper = styled.div`
	background-color: ${({ theme }) => theme.colors.black};
	height: 100%;
	width: 100%;
`;

const ProductSlider = ({ data, activeSite }) => {
	const moduleData = data?.modulSliderWizytowkiKategoriiProduktow || [];
	let header;
	let productsCategoriesArray;

	moduleData.forEach((item) => {
		if (item.__typename === 'Craft_modulSliderWizytowkiKategoriiProduktow_naglowek_BlockType') {
			header = item?.naglowek || '';
		} else if (item.__typename === 'Craft_modulSliderWizytowkiKategoriiProduktow_kategorieProduktow_BlockType') {
			productsCategoriesArray = item.wybierzKategorie;
		}
	});
	const [runAnimation, setRunAnimation] = useState(false);
	const triggerGreenCirclesAnimation = () => {
		setTimeout(() => {
			setRunAnimation(true);
		}, 1500);
	};
	return (
		<PageContainer className="full-width">
			<StyledBackgroundWrapper>
				<>
					<div className="inner-wrapper-large">
						<TextHeader>{header}</TextHeader>
					</div>
				</>
				{/* <div className="inner-wrapper-medium padding-right-none"> */}
				<Fade onReveal={() => triggerGreenCirclesAnimation()} right distance="300px" duration={700} delay={400}>
					<StyledSliderWrapper>
						<CustomSlider runAnimation={runAnimation} data={productsCategoriesArray} activeSite={activeSite} fullWidth />
					</StyledSliderWrapper>
				</Fade>
				{/* </div> */}
			</StyledBackgroundWrapper>
		</PageContainer>
	);
};

export default ProductSlider;
