import React from 'react';
import styled from 'styled-components';

const StyledInnerCircle = styled.div`
	//background-color: #04e99d;
	//background-color: red;
	//border-radius: 50%;
	//opacity: 0.08;
	//opacity: 0.3;
	//overflow-x: hidden;
	//position: relative;
	//z-index: -1;
	height: 50vh;
	opacity: 0.08;
	position: relative;
	width: 50vh;
	z-index: 1;
	div.bg-circle-1 {
		background-color: #04e99d;
		border-radius: 100%;
		height: 100%;
		left: 0;
		opacity: 0.5;
		position: absolute;
		top: 0;
		transform: scale(1.6);
		width: 100%;
	}
	div.bg-circle-2 {
		background-color: #04e99d;
		border-radius: 50%;
		height: 100%;
		left: 0;
		opacity: 0.5;
		position: absolute;
		top: 0;
		transform: scale(2.21875);
		width: 100%;
	}
	div.bg-circle-3 {
		background-color: #04e99d;
		border-radius: 50%;
		height: 100%;
		left: 0;
		opacity: 0.5;
		position: absolute;
		top: 0;
		transform: scale(3);
		width: 100%;
	}
	&.slajder {
		height: 160px;
		left: 30px;
		position: absolute;
		top: 30px;
		width: 160px;
		@media (min-width: 768px) {
			height: 200px;
			left: 90px;
			top: 90px;
			width: 200px;
		}
		@media (min-width: 1024px) {
			height: 300px;
			left: 120px;
			top: 120px;
			width: 300px;
		}
		@media (min-width: 1440px) {
			height: 350px;
			width: 350px;
			top: 225px;
			left: 225px;
		}
	}
`;

const ConcentricCircles = ({ className }) => (
	<StyledInnerCircle className={className}>
		<div className="bg-circle-1" />
		<div className="bg-circle-2" />
		<div className="bg-circle-3" />
	</StyledInnerCircle>
);

export default ConcentricCircles;
