import React from 'react';
import styled from 'styled-components';
import getHtmlFromRedactorField from '../../utils/getHtmlFromRedactorField';
// brakuje rwd
const Text = styled.div`
	font-family: ${({ theme }) => theme.fontFamily.mainFont};
	font-size: 22px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 35px;
	&.change-color {
		color: var(--text-color);
		transition: color 0.4s ease-in-out;
	}

	@media ${({ theme }) => theme.device.w_1024} {
		font-size: 26px;
		line-height: 36px;
	}

	@media ${({ theme }) => theme.device.w_1024} {
		font-size: 32px;
		line-height: 55px;
	}
	&.news {
		@media ${({ theme }) => theme.device.w_768} {
			font-size: 18px;
			line-height: 29px;
		}
		@media ${({ theme }) => theme.device.w_1024} {
			font-size: 26px;
			line-height: 36px;
		}
		@media ${({ theme }) => theme.device.w_1440} {
			font-size: 32px;
			line-height: 55px;
		}
	}
`;

const TextHeader3 = ({ className, children }) => {
	return <Text className={className} dangerouslySetInnerHTML={getHtmlFromRedactorField(children)} />;
};

export default TextHeader3;
