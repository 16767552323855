/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import Fade from 'react-reveal/Fade';
import PageContainer from '../common/PageContainer';
import TextHeader3 from '../typography/TextHeader3';
import TextNews from '../typography/TextNews';
import ButtonRectangle from '../common/ButtonRectangle';
import { Link } from 'gatsby';
import getSlug from '../../utils/getSlug';
import getDateInProperFormat from '../../utils/getDateInProperFormat';

const NEWS_CONTENT = 'news-content';

const StyledModuleWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	width: 100%;
	padding-bottom: 160px !important;
	background: black;
	//testowo
	&.allWhite {
		color: white !important;
		div {
			color: white !important;
		}
	}
`;

const StyledContentWrapper = styled.div`
	align-items: center;
	display: flex;
	flex-direction: column;
	height: 100%;
	justify-content: flex-start;
	margin-bottom: 85px;
	margin-top: 150px;
	width: 100%;
	@media ${({ theme }) => theme.device.w_1024} {
		margin-bottom: 150px;
	}
`;

const NewsData = styled.p`
	font-size: 14px;
	//line-height: 26px;
	color: ${({ theme }) => theme.colors.greyDark};
	@media ${({ theme }) => theme.device.w_1024} {
		font-size: 16px;
		line-height: 29px;
	}
`;

const StyledNewsBox = styled.div`
	align-items: flex-start;
	border-bottom: 0.5px solid;
	border-bottom-color: ${({ theme }) => theme.colors.greyDarkLine};
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr;
	grid-template-rows: repeat(3fr);
	justify-content: space-between;
	padding: 50px 8px 50px;
	transition: all 0.3s;
	&:hover {
		time {
			color: ${({ theme }) => theme.colors.green};
		}
		p {
			font-weight: ${({ theme }) => theme.fontWeight.regular};
		}
	}

	@media ${({ theme }) => theme.device.w_768} {
		padding: 50px 20px 50px 0px;
		grid-gap: 20px;
	}
	@media ${({ theme }) => theme.device.w_1024} {
		//grid-template-columns: 1fr 4fr 6fr;
		//grid-template-rows: 1fr;
		padding: 50px 20px 90px 0px;
		&:hover {
			padding: 80px 20px 130px 0px;
		}
	}
	@media ${({ theme }) => theme.device.w_1440} {
		grid-template-columns: 1fr 4fr 6fr;
		grid-template-rows: 1fr;
		padding: 50px 80px 120px 0px;
		grid-gap: 40px;
		&:hover {
			//box-shadow: 0 40px 44px -63px rgb(4 233 157 / 90%);
			padding: 80px 80px 130px 0px;
		}
	}

	.text-description {
		@media ${({ theme }) => theme.device.w_768} {
			width: 83%;
		}
		@media ${({ theme }) => theme.device.w_1440} {
			width: 100%;
		}
		@media ${({ theme }) => theme.device.w_1920} {
			padding-left: 8%;
		}
		@media ${({ theme }) => theme.device.w_2560} {
			padding-left: 12%;
		}
	}
	.text-header {
		position: relative;
		width: 80%;

		@media ${({ theme }) => theme.device.w_768} {
			width: 55%;
			top: -7px;
		}

		@media ${({ theme }) => theme.device.w_1440} {
			width: 100%;
		}
	}
`;

const StyledTextCircleWrapper = styled.div`
	display: flex;
	justify-content: space-between;
`;

const StyledCircle = styled.div`
	min-width: 45px;
	height: 45px;
	border: 1px solid ${({ theme }) => theme.colors.green};
	border-radius: 100px;
	position: relative;
	display: flex;
	// @media ${({ theme }) => theme.device.w_425} {
	// 	display: flex;
	// }

	@media ${({ theme }) => theme.device.w_1024} {
		min-width: 60px;
		height: 60px;
	}

	@media ${({ theme }) => theme.device.w_1440} {
		display: none;
	}
	&::after {
		content: '';
		position: absolute;
		width: 20px;
		background-color: var(--text-color);
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		height: 1px;
		margin: auto;
		@media ${({ theme }) => theme.device.w_1024} {
			width: 25px;
		}
	}
	&::before {
		content: '';
		position: absolute;
		width: 1px;
		background-color: var(--text-color);
		top: 0;
		bottom: 0;
		right: 0;
		left: 0;
		height: 20px;
		margin: auto;
		@media ${({ theme }) => theme.device.w_1024} {
			height: 25px;
		}
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	&.hidden {
		display: none;
	}
`;

const AllNews = ({ data, activeSite, allNewsData }) => {
	const [pageNumber, setPageNumber] = useState(1);
	const [cardsOnOneLoad, setCardsOnOneLoad] = useState(5);
	const [customCursor, setCustomCursor] = useState(' ');

	const newsData = allNewsData?.nodes || [];

	let limit = cardsOnOneLoad * pageNumber;
	const arrayNewsLength = newsData.length;
	let buttonVisibilityOnStart = arrayNewsLength <= limit;
	const cardsToShow = newsData.slice(0, limit);
	const IsButton = limit < arrayNewsLength;

	const moduleData = data?.modulWizytowkiNaPodstronieAktualnosci?.[0] || [];
	const buttonText = moduleData?.przyciskNapis || '';

	useEffect(() => {
		const getCustomCursor = document.getElementById('custom-cursor-news');
		setCustomCursor(getCustomCursor);
	});

	const handleMouseMoveOnSlider = (e) => {
		const offsetX = e.clientX - 34;
		const offsetY = e.clientY - 34;
		customCursor.style.top = `${offsetY}px`;
		customCursor.style.left = `${offsetX}px`;
	};

	const showMoreNews = () => {
		setPageNumber(pageNumber + 1);
	};

	return (
		<PageContainer className="full-width">
			<StyledModuleWrapper className="inner-wrapper-medium news allWhite">
				<StyledContentWrapper data-cursor={'green-circle'} id={NEWS_CONTENT} onMouseMove={(e) => handleMouseMoveOnSlider(e)}>
					{cardsToShow.map((item) => {
						const title = item?.wizytowkaAktualnosciTytul || '';
						const description = item?.wizytowkaAktualnosciOpis || '';
						const date = item?.postDate || '';
						const slug = item?.slug || '';
						const uri = item?.uri || '';
						const newDate = getDateInProperFormat(activeSite, date);
						return (
							<>
								<StyledNewsBox
									className="custom-cursor-animation custom-slider allWhite"
									as={Link}
									to={`/${getSlug(uri, activeSite)}`}
								>
									<NewsData>{newDate}</NewsData>

									<StyledTextCircleWrapper>
										<TextHeader3 className="change-color text-header news">{title}</TextHeader3>
										<StyledCircle />
									</StyledTextCircleWrapper>
									<TextNews className="change-color text-description">{description}</TextNews>
								</StyledNewsBox>
							</>
						);
					})}
				</StyledContentWrapper>

				{IsButton && (
					<ButtonWrapper onClick={() => showMoreNews()} className={buttonVisibilityOnStart ? 'hidden' : ''}>
						<ButtonRectangle className="change-color">{buttonText}</ButtonRectangle>
					</ButtonWrapper>
				)}
			</StyledModuleWrapper>
		</PageContainer>
	);
};

export default AllNews;
