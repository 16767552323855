// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react';
import styled from 'styled-components';
import manageScroll from '../../utils/manageScroll';

const StyledWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.blackMenuWrapper};
	display: flex;
	height: 100vh;
	justify-content: center;
	position: absolute;
	width: 100vw;
	z-index: 25;
`;

const StyledInfo = styled.p`
	color: white;
	font-size: 50px;
`;

const StyledCrossWrapper = styled.div`
	//margin-left: -20px;
	align-items: center;
	display: flex;
	height: 28px;
	justify-content: center;
	position: absolute;
	right: 7%;
	top: 4%;
	width: 28px;
	z-index: 15;
	&:hover {
		~ .menu-text {
			opacity: 0;
			transform: translateY(-50px);
		}
	}
	&.active {
		&:before {
			border: 1px solid ${({ theme }) => theme.colors.white};
			border-radius: 100px;
			content: '';
			height: 56px;
			left: 50%;
			position: absolute;
			top: 50%;
			transform: translate(-50%, -50%);
			width: 56px;
		}
	}
`;

const StyledCross = styled.div`
	//position: relative;
	background-color: var(--text-color);
	height: 1px;
	left: 0;
	top: 0;
	transform: rotate(-50deg);
	transition: all 0.25s;
	width: 26px;
	@media (min-width: 1024px) {
		background-color: ${({ theme }) => theme.colors.white};
	}

	&:before,
	&:after {
		background-color: var(--text-color);
		content: '';
		height: 1px;
		left: 0;
		position: absolute;
		transition: all 0.25s;
		width: 26px;
		@media (min-width: 1024px) {
			background-color: ${({ theme }) => theme.colors.white};
		}
	}
	&:before {
		top: -8px;
	}
	&:after {
		top: 8px;
	}
	&.active {
		background-color: transparent;
		transform: rotate(180deg);

		&:before,
		&:after {
			top: 0;
			transition: background-color 1.2s;
			background-color: white;
		}
		&:before {
			transform: rotate(45deg);
		}
		&:after {
			transform: rotate(-45deg);
		}
	}
`;

const FormModal = ({ isModal, infoSend }) => {
	const [isModalVisible, setIsModalVisible] = useState(true);

	manageScroll(isModal && isModalVisible);
	const crossHandler = () => {
		setIsModalVisible(false);
		manageScroll(isModal && isModalVisible);
	};

	return (
		<>
			{isModal && isModalVisible && (
				<StyledWrapper onClick={() => crossHandler()}>
					<StyledCrossWrapper className="custom-cursor-animation  active" onClick={() => crossHandler()}>
						<StyledCross className="active" />
					</StyledCrossWrapper>
					<StyledInfo>{infoSend}</StyledInfo>
				</StyledWrapper>
			)}
		</>
	);
};

export default FormModal;
