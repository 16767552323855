import React, { Component } from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
import PageContainer from '../common/PageContainer';
import TextHeader from '../typography/TextHeader';
import ButtonCircle from '../common/ButtonCircle';
import TextDescription from '../typography/TextDescription';
import TextDescription3 from '../typography/TextDescription3';

import VivusWrapper from '../VivusWrapper';
import getSlug from '../../utils/getSlug';

const StyledModuleWrapper = styled.div`
	//background-color: ${({ bgColor }) => bgColor};
	// padding: 50px 0;
	position: relative;
	transition: background-color 0.2s ease;
	width: 100%;
	z-index: 2;
	@media ${({ theme }) => theme.device.w_768} {
		padding: 00px 0 100px;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		padding: 30px 0;
		flex-direction: row;
		align-items: center;
		display: flex;
		justify-content: space-between;
	}
`;

const StyledContentWrapper = styled.div`
	padding: 0;
	position: relative;
	width: 100%;
	@media ${({ theme }) => theme.device.w_1024} {
	}
	@media ${({ theme }) => theme.device.w_1440} {
		padding: 160px 0 0;
	}
`;

const StyledHeadingWrapper = styled.div`
	align-items: flex-end;
	display: flex;
	justify-content: space-between;
	padding: 0 0 50px;
	position: relative;
	width: 100%;
	@media ${({ theme }) => theme.device.w_1024} {
		padding: 0 0 50px;
	}
`;

const StyledRowWrapper = styled.div`
	//padding: 200px 0;
	align-items: flex-start;
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 2fr 3fr;
	grid-template-rows: 1fr;
	justify-content: space-between;
	width: 100%;
	@media ${({ theme }) => theme.device.w_425} {
		grid-template-columns: 2fr 3fr;
		grid-gap: 30px;
	}

	@media ${({ theme }) => theme.device.w_768} {
		//grid-template-columns: repeat(2, 1fr);
		grid-gap: 30px;

		grid-template-columns: 1fr 3fr;
		gird-template-rows: 2fr;
		margin-top: 80px;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		grid-template-columns: 1fr 2fr 3fr;
		gird-template-rows: 1fr;
		margin-top: 120px;
		grid-gap: 40px;
	}
`;

const StyledIconWrapper = styled.div`
	align-items: flex-start;
	display: flex;
	justify-content: center;
	position: relative;
	@media ${({ theme }) => theme.device.w_768} {
		top: 8px;
	}
	@media ${({ theme }) => theme.device.w_1440} {
		right: 0px;
	}
`;

const StyledTextWrapper = styled.div`
	margin: 0 auto;
	width: 100%;
	@media ${({ theme }) => theme.device.w_1440} {
		width: 80%;
	}
	.only-tablet {
		display: none;
		margin-top: 16px;
		@media ${({ theme }) => theme.device.w_768} {
			display: block;
		}
		@media ${({ theme }) => theme.device.w_1440} {
			display: none;
		}
	}
`;

const StyledTextBigWrapper = styled.div`
	display: none;
	@media ${({ theme }) => theme.device.w_1024} {
		display: block;
	}
	.only-mobile-and-desktop {
		display: block;
		@media ${({ theme }) => theme.device.w_768} {
			display: none;
		}
		@media ${({ theme }) => theme.device.w_1440} {
			display: block;
		}
	}
`;

const StyledBoxesWrapper = styled.div`
	height: 100%;
	position: relative;
	width: 100%;
`;

const StyledButtonMobileWrapper = styled.div`
	align-items: center;
	display: flex;
	justify-content: flex-end;
	padding-bottom: 20px;
	position: relative;
	top: -40px;
	@media ${({ theme }) => theme.device.w_768} {
		display: none;
	}
`;

class Workflow extends Component {
	anchorElement = '';

	anchorElementOffsetTop = '';

	topNumberElement = '';

	bottomNumberElement = '';

	pathLength = '';

	path = '';

	componentDidMount() {
		const { data } = this.props;
		const moduleId = data?.remoteId + data?.__typename || '';
		this.anchorElement = document.getElementById(moduleId);
		this.path = document.querySelector('#greenLine');

		this.pathLength = this.path.getTotalLength();
		this.path.style.strokeDasharray = `${this.pathLength} ${this.pathLength}`;

		this.path.style.strokeDashoffset = this.pathLength;

		this.path.getBoundingClientRect();

		window.addEventListener('scroll', this.scrollHandler);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.scrollHandler);
	}

	scrollHandler = () => {
		const moduleOffsetTop = this.anchorElement?.offsetTop === null ? 0 : this.anchorElement?.offsetTop;
		const screenHeight = window.innerHeight || '';
		const pixelsInsideModule = window.scrollY + screenHeight - moduleOffsetTop - 450 || '';
		const moduleHeight = this.anchorElement?.clientHeight === null ? 0 : this.anchorElement?.clientHeight;

		let scrollPercentage;
		if (pixelsInsideModule < 0) {
			scrollPercentage = 0;
		} else {
			scrollPercentage = pixelsInsideModule / moduleHeight;
		}

		const drawLength = this.pathLength * scrollPercentage;
		this.path.style.strokeDashoffset = this.pathLength - drawLength;
		if (scrollPercentage >= 0.99) {
			this.path.style.strokeDasharray = 'none';
		} else {
			this.path.style.strokeDasharray = `${this.pathLength} ${this.pathLength}`;
		}
	};

	render() {
		const { data, activeSite } = this.props;

		const moduleData = data?.modulProcesPracy || [];
		const moduleId = data?.remoteId + data?.__typename || '';
		const blockArray = [];
		let header = '';
		let buttonLink = '';
		let buttonHref = '';
		let buttonDescription = '';

		moduleData.forEach((item) => {
			if (item.__typename === 'Craft_modulProcesPracy_naglowekPrzycisk_BlockType') {
				header = item?.naglowek || '';
				buttonLink = item?.przyciskHref || '';
				buttonHref = item?.przyciskLink?.[0]?.slug || '';
				buttonDescription = item?.przyciskTekst || '';
			}
			if (item.__typename === 'Craft_modulProcesPracy_piktogramNaglowekOpis_BlockType') {
				blockArray.push({
					heading: item?.naglowek || '',
					description: item?.opis || '',
					pictogram: item?.piktogram?.[0]?.url || '',
				});
			}
		});

		return (
			<PageContainer className="full-width">
				<StyledModuleWrapper id={moduleId} className="inner-wrapper-large padding-right">
					<StyledContentWrapper>
						<StyledHeadingWrapper>
							<div>
								<TextHeader className="workflow">{header}</TextHeader>
							</div>
							<ButtonCircle className="workflow-desktop" to={`/${getSlug(buttonLink, activeSite)}`} href={buttonHref}>
								{buttonDescription}
							</ButtonCircle>
						</StyledHeadingWrapper>

						<StyledBoxesWrapper>
							<svg className="path" id="green-line-svg" width="2" height="2500" viewBox="0 0 2 2500">
								<line id="greenLine" y2="2500" transform="translate(1)" fill="none" stroke="#02efb6" strokeWidth="2" />
							</svg>
							<Fade bottom>
								{blockArray.map((item) => {
									const heading = item?.heading || '';
									const description = item?.description || '';
									const pictogram = item?.pictogram || '';

									return (
										<StyledRowWrapper key={description}>
											<StyledIconWrapper>
												{heading && <VivusWrapper id={heading} fileName={pictogram} />}
											</StyledIconWrapper>
											<StyledTextWrapper>
												<TextDescription className="workflow">{heading}</TextDescription>
												<TextDescription3 className={`workflow only-tablet `}>{description}</TextDescription3>
											</StyledTextWrapper>
											<StyledTextBigWrapper>
												<TextDescription3 className="workflow only-mobile-and-desktop">
													{description}
												</TextDescription3>
											</StyledTextBigWrapper>
										</StyledRowWrapper>
									);
								})}
							</Fade>
						</StyledBoxesWrapper>
						<StyledButtonMobileWrapper>
							<ButtonCircle className="workflow-mobile" to={`/${getSlug(buttonLink, activeSite)}`} href={buttonHref}>
								{buttonDescription}
							</ButtonCircle>
						</StyledButtonMobileWrapper>
					</StyledContentWrapper>
				</StyledModuleWrapper>
			</PageContainer>
		);
	}
}

export default Workflow;
